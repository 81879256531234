import React, {useState, useEffect} from 'react';
import { ToastContainer, toast } from "react-toastify" 

import theme from "../theme.jsx";
import {
	Paper, 
	Button, 
	TextField
} from '@mui/material';
import { useParams, useNavigate, Link } from "react-router-dom";

const bodyDivStyle = {
	margin: "auto",
	textAlign: "center",
	width: "768px",
}



//const tmpdata = {
//	"username": "frikky",
//	"firstname": "fred",
//	"lastname": "ode",
//	"title": "topkek",
//	"companyname": "company here",
//	"email": "your email pls",
//	"phone": "PHONE!!",
//}

// FIXME - add fetch for data fields
// FIXME - remove tmpdata
// FIXME: Use isLoggedIn :)
const Settings = (defaultprops) => {
  const { globalUrl, isLoaded, } = defaultprops;
	//const alert = useAlert()

	const params = useParams();
	var props = JSON.parse(JSON.stringify(defaultprops))
	props.match = {}
	props.match.params = params

	const boxStyle = {
		flex: "1",
		marginLeft: "10px",
		marginRight: "10px",
		paddingLeft: "30px",
		paddingRight: "30px",
		paddingBottom: "30px",
		paddingTop: "30px",
		backgroundColor: theme.palette.surfaceColor,
		display: "flex", 
		flexDirection: "column",
		color: "white",
	}

	const [newPassword, setNewPassword] = useState("");
	const [newPassword2, setNewPassword2] = useState("");
	const [passwordFormMessage, setPasswordFormMessage] = useState("");

	const handleValidateForm = (password) => {
		return (password.length > 8);
	}

	const onPasswordChange = () => {
		const data = {"newpassword": newPassword, "newpassword2": newPassword2, "reference": props.match.params.key}
		const url = globalUrl+'/api/v1/passwordreset';
		fetch(url, {
			mode: 'cors',
			method: 'POST',
			body: JSON.stringify(data),
			credentials: 'include',
			crossDomain: true,
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
		})
		.then(response =>
			response.json().then(responseJson => {
				if (responseJson["success"] === false) {
					setPasswordFormMessage(responseJson["reason"])
					toast("Failed setting password. Is the link expired?")
				} else {
					setPasswordFormMessage("Successfully changed password! Redirecting to the login screen within 4 seconds")
					toast("Successfully changed password!")
					setTimeout(() => {
						window.location.pathname = "/login"
					}, 4000)
				}
			}),
		)
		.catch(error => {
			setPasswordFormMessage("Something went wrong.")
		});
	}

	// This should "always" have data
	useEffect(() => {
	})

	// Random names for type & autoComplete. Didn't research :^)
	const landingpageData = 
			<div style={{display: "flex", marginTop: "80px"}}>
				<Paper style={boxStyle}>
				<h2>Password Reset</h2>
				<div style={{flex: "1", display: "flex", flexDirection: "column", color: "white",}}>
					<TextField
						required
						fullWidth={true}
						style={{backgroundColor: theme.palette.inputColor}}
						InputProps={{
							style:{
								height: "50px", 
								color: "white",
								fontSize: "1em",
							},
						}}
						placeholder="New password"
						type="password"
						id="standard-required"
						autoComplete="password"
						margin="normal"
						variant="outlined"
						onChange={e => setNewPassword(e.target.value)}
						helperText={handleValidateForm(newPassword) ? "" : "Password must be at least 9 characters long"}
					/>
					<TextField
						required
						fullWidth={true}
						style={{backgroundColor: theme.palette.inputColor}}
						InputProps={{
							style:{
								height: "50px", 
								color: "white",
								fontSize: "1em",
							},
						}}
						type="password"
						placeholder="Repeat new password"
						id="standard-required"
						autoComplete="password"
						margin="normal"
						variant="outlined"
						onChange={e => setNewPassword2(e.target.value)}
						helperText={handleValidateForm(newPassword2) ? "" : "Password must be at least 9 characters long and match the first password"}
					/>
				</div>
				<Button
					disabled={(newPassword.length < 9 || newPassword2.length < 9 ) || newPassword !== newPassword2}
					style={{width: "100%", height: "60px", marginTop: "10px"}}
					variant="contained"
					color="primary"
					onClick={() => onPasswordChange()}
				>
				Submit password change
				</Button>
				<h3>{passwordFormMessage}</h3>
			</Paper>
		</div>

const loadedCheck = isLoaded ? 
	<div style={bodyDivStyle}>
		{landingpageData}
	</div>
	:
	<div>
	</div>

return(
	<div>
		{loadedCheck}
	</div>
)
}
export default Settings;
