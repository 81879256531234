import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Grid, Container } from '@mui/material';
import theme from '../theme.jsx';
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
    Typography,
} from '@mui/material';

const PartnerItems = (props) => {
    const {partnerLogo, partnerText, partnerUrl, partnerName,} = props
    const [hover, setHover] = React.useState(false);
    const imggrow = {
        width: "100%",
        transition: "transform .2s",
        borderRadius: theme.palette?.borderRadius,
        float: "center",
        textAlign: "center",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        color: "black",
        position: "relative",
        overflow: "hidden",
        zIndex: "1",
        height: hover ? "280px" : "80px",
        transform: hover ? "transform(1.5)" : "transform .2s",
        cursor: hover ? "pointer" : "",
        borderColor: hover ? "#3585f9" : "",
        display: hover ? "block" : "",
        boxShadow: hover ? "0 4px 8px #FC7305" : "",
    }

    const imgstyle = {
        margin: "15px auto 0px auto",
        justifyContent: "center",
        textAlign: "center",
        height: 50,
        maxHeight: 50,
        minHeight: 50,
    }

    const partnerContainer =  {
      position: "relative ",
      padding: 4,
    }

    return (
      <div style={partnerContainer}>
        <div style={imggrow} onMouseOver={() => { 
						setHover(true);
					}} 
					onMouseOut={() => { 
						setHover(false); 
					}}
					onClick={() => {
						ReactGA.event({
							category: "partner_carousel",
							action: `${partnerName}_click`,
							label: "",
						})

						window.open(partnerUrl, "_blank");
					}}
					>
            <img style={imgstyle} src={partnerLogo} /><>
            <div style={{padding: "15px 10px 15px", }}>
							<Typography variant="h6" style={{ textAlign: "center", }}>
								{partnerName}
							</Typography>
							<Typography variant="body1" color="textSecondary" style={{ textAlign: "center", marginTop: 10, }}>
								{partnerText}
							</Typography>
						</div>
            </>
						<br>
						</br>
						<a href={partnerUrl}>Visit their Website</a>
        </div>
      </div>
    )};

export default PartnerItems; 
