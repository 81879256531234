import React, { useState } from 'react';
import { ToastContainer, toast } from "react-toastify" 
import { BrowserView, MobileView } from "react-device-detect";
import { Paper, Button, Typography, TextField } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next'

const bodyDivStyle = {
	margin: "auto",
	textAlign: "center",
	width: "900px",
}

// Should be different if logged in :|
const Contact = (props) => {
	const { globalUrl, surfaceColor, newColor, mini, textColor, inputColor } = props;
	const { t } = useTranslation('');
	const [dealCountry, setDealCountry] = React.useState("United States");
	const [dealValue, setDealValue] = React.useState("");
	const [dealType, setDealType] = React.useState("MSSP");

	const products = [
		{ code: '', label: 'MSSP', phone: '' },
		{ code: '', label: 'Enterprise', phone: '' },
		{ code: '', label: 'Consultancy', phone: '' },
		{ code: '', label: 'Support', phone: '' },
	]

	const minimize = mini !== undefined && mini
	const textcolor = textColor !== undefined ? textColor : "#ffffff"
	const inputcolor = newColor !== undefined ? newColor : inputColor

	const boxStyle = {
		flex: "1",
		marginLeft: "10px",
		marginRight: "10px",
		paddingLeft: "30px",
		paddingRight: "30px",
		paddingBottom: "30px",
		paddingTop: "30px",
		display: "flex",
		flexDirection: "column"
	}

	const bodyTextStyle = {
		color: "#ffffff",
	}

	const [firstname, setFirstname] = useState("");
	const [lastname,] = useState("");
	const [title,] = useState("");
	const [companyname,] = useState("");
	const [email, setEmail] = useState("");
	const [phone,] = useState("");
	const [message, setMessage] = useState("");

	const [formMessage, setFormMessage] = useState("");

	const submitContact = () => {
		const data = {
			"firstname": firstname,
			"lastname": lastname,
			"title": title,
			"companyname": companyname,
			"email": email,
			"phone": phone,
			"message": message,
		}
		console.log(data)

		fetch(globalUrl + "/api/v1/contact", {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		})
			.then(response => response.json())
			.then(response => {
				if (response.success === true) {
					setFormMessage(response.message)
					toast("Thanks for submitting!")
					setMessage("")
					//setEmail("")
					//setName("")
				} else {
					const msg = "Something went wrong. Please contact frikky@shuffler.io directly."
					if (response.reason !== undefined && response.reason !== null) {
						setFormMessage(response.reason)
					} else {
						setFormMessage(msg)
						toast(msg)
					}
				}
			})
			.catch(error => {
				console.log(error)
			});
	}

	// Random names for type & autoComplete. Didn't research :^)
	const landingpageDataBrowser =
		<div>
			<div style={{ paddingTop: 100 }} />
			<div style={bodyTextStyle}>
				<Typography variant="body1" style={{ color: "#f85a3e" }}>
				  <Trans t={t}>
						Contact us
					</Trans>
				</Typography>
				<Typography variant="h6">
				  <Trans t={t}>
						Lets talk!
					</Trans>
				</Typography>
			</div>
			<div style={{ display: "flex", marginTop: 15, }}>
				<Paper elevation={mini === true ? 0 : 0} style={boxStyle}>
					<Typography variant="h6" style={{ color: "white", marginTop: 10, marginBottom: 10, }}>
				  	<Trans t={t}>
							Contact Details
						</Trans>
					</Typography>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", marginRight: "15px", }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Name"
							type="firstname"
							id={minimize ? "contact_name_mini" : "contact_name"}
							autoComplete="firstname"
							margin="normal"
							variant="outlined"
							onChange={e => setFirstname(e.target.value)}
						/>
						<TextField
							required
							style={{ flex: "1",  }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Email"
							type="email"
							id={minimize ? "email_field_contact_mini" : "email_field_contact"}
							autoComplete="email"
							margin="normal"
							variant="outlined"
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
					{/*
					<div style={{ display: "flex", marginTop: 15, }}>
						<TextField
							style={{
								marginTop: 0,
								flex: 1,
								marginRight: 10,
							}}
							InputProps={{
								style: {
									height: 50,
									color: "white",
								},
							}}
							color="primary"
							required
							fullWidth={true}
							placeholder="1000"
							label="Value (USD)"
							type="text"
							id="standard-required"
							margin="normal"
							variant="outlined"
							defaultValue={dealValue}
							onChange={(e) => {
								setDealValue(e.target.value)
							}}
						/>
						<Autocomplete
							id="country-select"
							sx={{ width: 250 }}
							options={countries}
							variant="outlined"
							autoHighlight
							getOptionLabel={(option) => option.label}
							onChange={(event, newValue) => {
								setDealCountry(newValue.label)
							}}
							renderOption={(props, option) => (
								<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									<img
										loading="lazy"
										width="20"
										src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
										srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
										alt=""
									/>
									{option.label} ({option.code}) +{option.phone}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									style={{
										flex: 1,
										marginTop: 0,
										marginRight: 10,
									}}
									variant="outlined"
									label="Choose a country"
									defaultValue={dealCountry}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
								/>
							)}
						/>
						<Autocomplete
							id="product-select"
							sx={{ width: 250 }}
							options={products}
							variant="outlined"
							autoHighlight
							onChange={(event, newValue) => {
								setDealType(newValue)
							}}
							getOptionLabel={(option) => option.label}
							renderOption={(props, option) => (
								<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
									{option.label}
								</Box>
							)}
							renderInput={(params) => (
								<TextField
									{...params}
									style={{
										flex: 1,
										marginTop: 0,
									}}
									variant="outlined"
									label="Choose a product"
									defaultValue={dealType}
									inputProps={{
										...params.inputProps,
										autoComplete: 'new-password', // disable autocomplete and autofill
									}}
								/>
							)}
						/>
					</div>
					*/}
					<div style={{ flex: 1 }}>
						<Typography variant="h6" style={{ color: "white", marginTop: 10, marginBottom: 10, }}>
				  		<Trans t={t}>
								Message
							</Trans>
						</Typography>
					</div>
					<div style={{ flex: 4 }}>
						<TextField
							multiline
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							style={{ flex: "1",  }}
							rows="6"
							fullWidth={true}
							placeholder={t("contact.help")}
							type="message"
							id="filled-multiline-static"
							margin="normal"
							variant="outlined"
							onChange={e => setMessage(e.target.value)}
						/>
					</div>
					<Button
						disabled={email.length <= 0 || message.length <= 0}
						style={{ width: "100%", height: "60px", marginTop: "10px" }}
						variant="contained"
						color="primary"
						onClick={submitContact}
					>
						Submit
					</Button>
					<Typography variant="body1" style={{ color: "white", marginTop: 10, }}>
							{formMessage}
					</Typography>
				</Paper>
			</div >
		</div >

	const landingpageDataMobile =
		<div style={{ paddingBottom: "50px" }}>
			{minimize ?
				<div style={{ color: textcolor, textAlign: "center" }}>
					<Typography variant="h4" style={{ marginTop: 15, }}>
				  	<Trans t={t}>
							Contact us
						</Trans>
					</Typography>
				</div>
				:
				<div style={{ color: textcolor, textAlign: "center" }}>
					<h3 style={{}}>
				  	<Trans t={t}>
							Contact us
						</Trans>
					</h3>
					<h2>
				  	<Trans t={t}>
							What can we do for you?
						</Trans>
					</h2>
				</div>}
			<div style={{ display: "flex", marginTop: 10, }}>
				<Paper elevation={mini === true ? 0 : 0} style={boxStyle}>
					<Typography variant="h6" style={{ color: textcolor }}>Contact Details</Typography>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Name"
							type="firstname"
							id="firstname"
							autoComplete="firstname"
							margin="normal"
							variant="outlined"
							onChange={e => setFirstname(e.target.value)}
						/>
					</div>
					<div style={{ flex: "1", display: "flex", flexDirection: "row" }}>
						<TextField
							required
							style={{ flex: "1", }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							fullWidth={true}
							placeholder="Email"
							type="email"
							id="email-field-contact"
							autoComplete="email"
							margin="normal"
							variant="outlined"
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
					<div style={{ flex: 1, marginTop: 20, }}>
						<Typography variant="h6" style={{ color: textcolor }}>Message</Typography>
					</div>
					<div style={{ flex: 4 }}>
						<TextField
							multiline
							style={{ flex: "1", }}
							InputProps={{
								style: {
									color: textcolor,
								},
							}}
							color="primary"
							rows="6"
							fullWidth={true}
							placeholder="What can we help you with?"
							id="filled-multiline-static"
							type="message"
							margin="normal"
							variant="outlined"
							onChange={e => setMessage(e.target.value)}
						/>
					</div>
					<Button
						disabled={email.length <= 0 || message.length <= 0}
						style={{ width: "100%", height: "60px", marginTop: "10px" }}
						variant="contained"
						color="primary"
						onClick={submitContact}
					>
						Submit
					</Button>
					<h3>{formMessage}</h3>
				</Paper>
			</div>
		</div>


	const loadedCheck =
		<div>
			{minimize ?
				landingpageDataMobile
				:
				<div style={{ paddingBottom: 250 }}>
					<BrowserView>
						<div style={bodyDivStyle}>{landingpageDataBrowser}</div>
					</BrowserView>
					<MobileView>
						{landingpageDataMobile}
					</MobileView>
				</div>
			}
		</div>

	return (
		<div>
			{loadedCheck}
		</div>
	)
}
export default Contact;
