import React, {useState } from 'react';

import theme from '../theme.jsx';
import { LinearProgress, Divider, Paper, Card, ListItem, ListItemText, List, CardActionArea, Button, Grid, Typography } from '@mui/material';

import { usecaseTypes } from "../components/UsecaseSearch.jsx"

const images = {
	"email": ["https://storage.googleapis.com/shuffle_public/app_images/Gmail_794e51c3c1a8b24b89ccc573a3defc47.png", "https://storage.googleapis.com/shuffle_public/app_images/Outlook_Office365_accdaaf2eeba6a6ed43b2efc0112032d.png", ],
	"intel": ["https://storage.googleapis.com/shuffle_public/app_images/Yara_2b1264f213f3636682cc51d49ee470be.png", "https://storage.googleapis.com/shuffle_public/app_images/MISP_c69ea55c02913030b1cd546f86187878.png", ],
	"communication": ["https://storage.googleapis.com/shuffle_public/app_images/Slack_d641997f3f1657ec4b0c91cba020838b.png", "https://storage.googleapis.com/shuffle_public/app_images/Microsoft_Teams_7ee10aabc5dff1f8faeb8885d82c038b.png", ],
	"edr": ["https://storage.googleapis.com/shuffle_public/app_images/Carbon_Black_Response_e9fa2602ea6baafffa4b5eec722095d3.png", "https://storage.googleapis.com/shuffle_public/app_images/Wazuh_fb715a176a192687e95e9d162186c97f.png", ],
	"cases": ["https://storage.googleapis.com/shuffle_public/app_images/TheHive_5_5fefa1911e01a005b54f94dcb6830d82.png", "https://storage.googleapis.com/shuffle_public/app_images/Zoho_Desk_14b085b64bf74d7ace3afb3d3406a21c.png", "https://storage.googleapis.com/shuffle_public/app_images/Servicenow_API_b9c2feaf99b6309dabaeaa8518c61d3d.png"],
	"siem": ["https://storage.googleapis.com/shuffle_public/app_images/Elasticsearch_971706758e274c2e4083f2621fb5a6f7.png", "https://storage.googleapis.com/shuffle_public/app_images/QRadar_96a3d95a2a73cfdb51ea4a394287ed33.png", "https://storage.googleapis.com/shuffle_public/app_images/Splunk-openapi_1995363ec370368ed05a2882ec0ea8fc.png", ],
	"iam": ["https://storage.googleapis.com/shuffle_public/app_images/Okta_OpenAPI_0966d65ac6271b084a0399d2bd97ef51.png", "https://storage.googleapis.com/shuffle_public/app_images/Microsoft_Identity_and_Access_1ce1b7a6ee2ed37aa695f336c40159db.png", ],
	"assets": ["https://storage.googleapis.com/shuffle_public/app_images/AWS_Application_Discovery_Service_82db9f20593d503ef28c91a76f99992a.png", "https://storage.googleapis.com/shuffle_public/app_images/DefectDojo_8d4d728af6d8bee265008663d8e724d1.png", "https://storage.googleapis.com/shuffle_public/app_images/IBM_Containers_API_84175c5c946984a834de854e36d35435.png", "https://storage.googleapis.com/shuffle_public/app_images/Wazuh_fb715a176a192687e95e9d162186c97f.png", ],
}


const ShowUsecaseSimplified = (props) => {
	const { isMobile, usecase } = props;

	const boxWidth = isMobile ? "100%" : 742
	const boxHeight = isMobile ? 200 : 250 
	const borderWidth = 2 

	// Find the correct usecase
	const lowercaseUsecase = usecase.toLowerCase()
	const foundUsecase = usecaseTypes.find((usecaseType) => {
		return usecaseType.name.toLowerCase() === lowercaseUsecase || usecaseType.aliases.includes(lowercaseUsecase)
	})

	var srcimg = ""
	var srctext = ""

	var midimg = ""
	var midtext = ""

	var dstimg = ""
	var dsttext = ""

	if (!foundUsecase) {
		console.log("Could not find usecase: ", usecase)
	} else {
		if (foundUsecase.value !== undefined && foundUsecase.value !== null && foundUsecase.value.length > 0) { 
			// Get random item from images["email"] list
			const srcapp = foundUsecase.value[0].items[0].app_type
			srcimg = images[srcapp][Math.floor(Math.random() * images[srcapp].length)];
			srctext = foundUsecase.value[0].items[0].name
			if (srctext.includes("When")) {
				srctext = srctext.split("When")[1]
			}

			if (foundUsecase.value[0].items.length > 2) {
				const midapp = foundUsecase.value[0].items[1].app_type
				midimg = images[midapp][Math.floor(Math.random() * images[midapp].length)];
				midtext = foundUsecase.value[0].items[1].name

				const dstapp = foundUsecase.value[0].items[2].app_type
				dstimg = images[dstapp][Math.floor(Math.random() * images[dstapp].length)];
				dsttext = foundUsecase.value[0].items[2].name

			} else if (foundUsecase.value[0].items.length > 1) {
				const dstapp = foundUsecase.value[0].items[1].app_type
				dstimg = images[dstapp][Math.floor(Math.random() * images[dstapp].length)];
				dsttext = foundUsecase.value[0].items[1].name
			} else {
				console.log("Not enough usecases for: ", foundUsecase)
			}
		}
	}

	const imagestyle = {
		width: 60, 
		height: 60, 
		borderRadius: 60, 
		margin: "auto", 
		display: "block", 
		border: "2px solid rgba(255,255,255,0.3)",
	}

	const boxStyle = {
		marginTop: 10, 
		minWidth: 119,
		maxWidth: 119,
		minHeight: 72,
		maxHeight: 72,
		padding: 16,
		borderRadius: 8,
		backgroundColor: theme.palette.surfaceColor,
		textAlign: "center", 
	}

	return (
		<div style={{minWidth: boxWidth, maxWidth: boxWidth, minHeight: boxHeight, padding: `${borderWidth}px ${borderWidth}px 0px ${borderWidth}px`, position: "relative", background: "linear-gradient(90deg, #F86744 0%, #F34475 100%)", margin: "auto", color: "white",	borderRadius: "8px 8px 0px 0px", zIndex: 500, }}>
			<div style={{backgroundColor: "#1f2023", borderRadius: "8px 8px 0px 0px", width: "100%", height: boxHeight+10, padding: isMobile ? "30px 0px 0px 0px": "70px 0px 0px 0px", }}>
				<span style={{width: isMobile ? 250:502, margin: "auto", display: "flex", itemAlign: "center", justifyContent: "center", }}>
					<div style={{marginRight: isMobile ? 10:50, }}>
						<img src={srcimg} style={imagestyle} />
						<div style={boxStyle}>
							<Typography variant="body1" color="textSecondary">
								When
							</Typography> 
							<Typography variant="body1">
								{srctext}
							</Typography> 
						</div> 
					</div>
					<div style={{}}>
						<img src={dstimg} style={imagestyle} />
						<div style={boxStyle}>
							<Typography variant="body1" color="textSecondary">
								Then	
							</Typography> 
							<Typography variant="body1">
								{dsttext}
							</Typography> 
						</div> 
					</div>
				</span>
			</div>
		</div>
	)
}

export default ShowUsecaseSimplified;
