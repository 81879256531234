import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Grid, Container } from '@mui/material';
import theme from '../theme.jsx';
import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
    Typography,
} from '@mui/material';

const LogoItems = (props) => {
    const {companyLogo, partnerText, partnerUrl, partnerName,} = props
    const [hover, setHover] = React.useState(false);

    const logoContainer =  {
      position: "relative ",
      padding: 4,
    }

    return (
      <div style={logoContainer}>
		<img src={companyLogo} />
      </div>
    )};

export default LogoItems; 
