import i18n from 'i18next';
//import { initReactI18next } from 'react-i18next/hooks';

//import XHR from 'i18next-xhr-backend';
//i18next-localstorage-backend

import HttpApi from 'i18next-http-backend'; // fallback http load
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import Backend from 'i18next-chained-backend';

import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // // detect user language
  // // learn more: https://github.com/i18next/i18next-browser-languageDetector
	// doesn't work well with SSR in react
  // .use(LanguageDetector)
  // // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
		react: {
      useSuspense: false,
    },
    debug: false, // helps with a lot of noise during tests 
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
		backend: {
			backends: [
				LocalStorageBackend,  
				HttpApi              
			],
			backendOptions: [{
				/* options for primary backend */
			}, {
			/* options for secondary backend */
			loadPath: '/locales/{{lng}}/{{ns}}.json' // http load path for my own fallback
			}]
		}
  });


export default i18n;
