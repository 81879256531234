import React, { useState, useEffect, useLayoutEffect, useRef } from "react";

import {
  Typography,
  Tooltip,
  Button,
	ButtonGroup,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
	Divider,
} from "@mui/material";

const CreatorSuggestions = (defaultprops) => {
	const { globalUrl, creatorProfile, userdata, creatorName } = defaultprops;
	const [suggestions, setSuggestions] = useState([]);


  const GetSuggestions = (creator) => {
    fetch(
      `${globalUrl}/api/v1/creators/${creator}/suggestions`,
      {
        method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
		},
		credentials: "include",
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          console.log("Status not 200 for creator stats :O!");
        }
        return response.json();
      })
      .then((responseJson) => {
		if (responseJson !== undefined && responseJson !== null && responseJson.success !== false) {
			setSuggestions(responseJson);
		}
      })
      .catch((error) => {
        console.log(" Error: " + error.toString());
      });
  };

	useEffect(() => {
		GetSuggestions(creatorName);
	}, []);

	if (creatorProfile.self !== true) {
		return null
	}

	const handleSuggestionApproval = (creator, suggestion_id, action) => {
		const suggestionData = {
			"action": action,
		}

		fetch(
		  `${globalUrl}/api/v1/creators/${creator}/suggestions/${suggestion_id}`,
		  {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			credentials: "include",
			body: JSON.stringify(suggestionData),
		  }
		)
	    .then((response) => {
	      if (response.status !== 200) {
	        console.log("Status not 200 for creator stats :O!");
	      }

	      return response.json();
	    })
	    .then((responseJson) => {
	      	if (responseJson.success === true) {
	      		GetSuggestions(creator) 
	      	}
	    })
	    .catch((error) => {
	      console.log(" Error: " + error.toString());
	    });
	}

	return (
		<div style={{marginTop: 50, }}> 
			<Typography variant="h4">
				Suggestions ({suggestions.length})
			</Typography>
			{suggestions === undefined || suggestions === null || suggestions.length === 0 ? 
				<Typography variant="h6" style={{marginTop: 15, }}>
					Your suggestions from the community will show up here. Primary suggestions are suggestions that are made by other creators or platform users. Shuffle will additionally suggestion what you can do to improve your apps, or for things you may want to do. 
				</Typography>
			: 
			<div style={{marginTop: 10, }}>
				{suggestions.map((suggestion, index) => {
					// Weird, but working way to handle suggestions~
					// This needs to change, but is just temporary
					var defaultReturn =
						<Typography style={{marginTop: 10, marginBottom: 10, }}>
							Unknown Suggestion Type: {suggestion.type}
						</Typography>

					if (suggestion.type === "" || suggestion.type === "action_label") {
						defaultReturn =
							<div style={{display: "flex"}}>
								<img src={suggestion.label.image_url} style={{width: 50, height: 50, marginRight: 10, }} />
								<Typography style={{marginTop: 13, minWidth: 480, maxWidth: 480, marginRight: 20, }}>
									Add label '{suggestion.label.label}' to '{suggestion.label.action_name}'
								</Typography>
								<ButtonGroup>
									<Button style={{marginLeft: "auto", marginTop: 10, marginBottom: 10, }} variant="contained" color="primary" onClick={() => {
										handleSuggestionApproval(creatorName, suggestion.suggestion_id, "approve");
									}}>
										Approve	
									</Button>
									<Button style={{marginLeft: "auto", marginTop: 10, marginBottom: 10, }} variant="contained" color="secondary" onClick={() => {
										handleSuggestionApproval(creatorName, suggestion.suggestion_id, "reject");
									}}>
										Deny
									</Button>
								</ButtonGroup>
							</div>
					}

					return (
						<div key={index}>
							<Divider />

							{defaultReturn}
						</div>
					)
				})}
			</div>
			}
				
		</div> 
	)
}

export default CreatorSuggestions;
