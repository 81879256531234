import React, { useState, useEffect, useRef, memo, useContext } from 'react';
import { isMobile } from "react-device-detect";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
    Box,
    Paper,
    Typography,
    Button,
    Grid,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Card,
    CardContent,
    Table,
} from "@mui/material";
import ReactGA from "react-ga4";
import {
    ArrowForward as ArrowForwardIcon,
} from '@mui/icons-material';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import { Context } from '../context/ContextApi';

const ProfessionalSupport = (props) => {
    const { globalUrl, isLoaded, serverside, userdata, isCloud, isLoggedIn} = props;
    const [isHovered, setIsHovered] = React.useState(false);
    const [selected, setSelected] = useState(0)
    const supportOptionsRef = useRef(null);
    const navigate = useNavigate();
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleScroll = () => {
        if (supportOptionsRef.current) {
            supportOptionsRef.current.scrollIntoView({ behavior: "smooth" });
        }
        navigate(`${window.location.pathname}#options`);
    };

    const buttonItems = [{
        "title": "Unlimited support",
        "text": "The Shuffle team is ready to provide guidance and effective resolutions to minimize risk exposure.<br /><br /> Engage support seamlessly through the Shuffle support portal. Track your issues, open new tickets, and schedule a health check. We've got your back covered. Once you open your ticket the right resource will get back to you based on the type of issue and timezone.",
    }, {
        "title": "Health checks and upgrades",
        "text": "You may not know it, and there may still be ways to derive more value from your Shuffle installation.<br /><br /> Rely on our health checks for actionable recommendations to optimize the platform and achieve your cybersecurity goals. Let the team work with you on upgrading your Shuffle deployment to the newest version and benefit from the enhanced and extra features while ensuring your custom work stays in place.",
    },
    {
        "title": "Customer success",
        "text": "You are not alone. Shuffle has a customer-oriented approach across the company that is centralized on the Customer Success team.<br /><br /> A customer success manager (CSM) will be assigned to the project, becoming the customer extension within the Shuffle team. Your CSM will become your main point of contact during this journey, welcoming you during the Onboarding session and following up in QBR meetings.",
    },
    {
        "title": "Architecture design",
        "text": "For self-hosted deployments, this is the way to start. The right architecture and hardware specs will save you headaches later on.<br /><br /> Our team of solution architects designs the best possible architecture based on the environment in scope, the load expected to be generated, and your particular use cases. This exercise includes the hardware specifications for the central components of the Shuffle platform.",
    },
    ]

    const cards = [
        "Integration Development", "App Development", "Workflow development",
        "Configuration Setup", "Deployment Setup", "Specific Bug Fixes", "Platform Feature Requests"
        , "Process Improvements", "Advisory"
    ];
    const createData = (serviceName, Standard, Premium) => {
        return { serviceName, Standard, Premium };
    };

    const rows = [
        createData('Support coverage', '8/5', '24/7'),
        createData('Maximum response time', '8 business hours', '4 business hours'),
        createData('Unlimited technical inquiries', <CheckCircleOutlineIcon />, <CheckCircleOutlineIcon />),
        createData('Assisted upgrades to the newest version', <CheckCircleOutlineIcon />, <CheckCircleOutlineIcon />),
        createData('Health checks', '2 per year', '4 per year'),
    ];

    const textStyle = {
        cursor: "pointer",
        marginBottom: 10,
        height: 50,
        maxWidth: 260,
        paddingTop: 15,
    }

    const renderSelectedDiv = () => {
        switch (selected) {
            case 1:
                return (
                    <div style={{
                        borderBottom: "1px solid #f86a3e",
                        display: "block",
                        height: 0,
                        pointerEvents: "none",
                        width: 75,
                        zIndex: 1,
                        bottom: 37,
                        position: "relative",
                        textAlign: "center"
                    }}>
                        <span style={{
                            position: "absolute",
                            right: "78%",
                            top: -2,
                            transform: "translateY(-50%)",
                            color: "#f86a3e",
                            fontSize: 40
                        }}>•</span>
                        <span style={{
                            position: "absolute",
                            right: -4,
                            top: -2,
                            transform: "translateY(-50%)",
                            color: "#f86a3e",
                            fontSize: 40
                        }}>•</span>
                    </div>
                );
            case 0:
                return (
                    <div style={{
                        borderBottom: "1px solid #f86a3e",
                        display: "block",
                        height: 0,
                        pointerEvents: "none",
                        width: 75,
                        zIndex: 1,
                        bottom: 112,
                        position: "relative",
                        textAlign: "center"
                    }}>
                        <span style={{
                            position: "absolute",
                            right: "78%",
                            top: -2,
                            transform: "translateY(-50%)",
                            color: "#f86a3e",
                            fontSize: 40
                        }}>•</span>
                        <span style={{
                            position: "absolute",
                            right: -4,
                            top: -2,
                            transform: "translateY(-50%)",
                            color: "#f86a3e",
                            fontSize: 40
                        }}>•</span>
                    </div>
                );
            case 3:
                return (
                    <div style={{
                        borderBottom: "1px solid #f86a3e",
                        display: "block",
                        height: 0,
                        pointerEvents: "none",
                        width: 75,
                        zIndex: 1,
                        top: 113,
                        position: "relative",
                        textAlign: "center"
                    }}>
                        <span style={{
                            position: "absolute",
                            right: "78%",
                            top: -2,
                            transform: "translateY(-50%)",
                            color: "#f86a3e",
                            fontSize: 40
                        }}>•</span>
                        <span style={{
                            position: "absolute",
                            right: -4,
                            top: -2,
                            transform: "translateY(-50%)",
                            color: "#f86a3e",
                            fontSize: 40
                        }}>•</span>
                    </div>
                );
            case 2:
                return (
                    <div style={{
                        borderBottom: "1px solid #f86a3e",
                        display: "block",
                        height: 0,
                        pointerEvents: "none",
                        width: 75,
                        zIndex: 1,
                        top: 37,
                        position: "relative",
                        textAlign: "center"
                    }}>
                        <span style={{
                            position: "absolute",
                            right: "78%",
                            top: -2,
                            transform: "translateY(-50%)",
                            color: "#f86a3e",
                            fontSize: 40
                        }}>•</span>
                        <span style={{
                            position: "absolute",
                            right: -4,
                            top: -2,
                            transform: "translateY(-50%)",
                            color: "#f86a3e",
                            fontSize: 40
                        }}>•</span>
                    </div>
                );
            default:
                return null;
        }
    }

    const backgroundStyle = {
        backgroundImage: "url('images/course_page/course.svg')",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: 1,
    };

    return (
        <Wrapper isLoggedIn={isLoggedIn}>
            <div style={{
                fontFamily: 'sans-serif',
                color: '#fff',
                textAlign: 'center',
                position: 'relative',
                width: isMobile ? 300 : 900,
                background: '#212121',
                borderRadius: '20px',
                marginLeft: "auto",
                marginRight: "auto",
                padding: 20,
            }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ margin: isMobile?null: 'auto' }}>
                    <Grid item xs={12} md={isMobile ? 12:4} style={{ textAlign: isMobile ? 'center' : 'left'}}>
                        <Typography style={{
                            fontSize: isMobile ? '2em' : '2.5em',
                            marginBottom: '20px',
                            width: isMobile ? '100%' : 400,
                        }}>
                            Professional Services
                        </Typography>
                        <div style={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: '10px',
                            marginBottom: '20px',
                        }}>
                            <Button style={{
                                backgroundColor: '#FF8444',
                                color: '#ffffff',
                                border: 'none',
                                borderRadius: '5px',
                                width: isMobile ? '100%' : 215,
                                textTransform: 'capitalize',
                                cursor: 'pointer',
                            }}
                                onClick={() => window.location.href = '/contact'}
                            >
                                Request more info
                            </Button>
                            <Button style={{
                                backgroundColor: '#494949',
                                color: '#ffffff',
                                border: 'none',
                                borderRadius: '5px',
                                width: isMobile ? '100%' : 215,
                                textTransform: 'capitalize',
                                cursor: 'pointer',
                            }}
                                onClick={() => window.location.href = '/admin?admin_tab=billing'}
                            >
                                User Login In
                            </Button>
                        </div>
                    </Grid>
                    {!isMobile && (
                        <Grid item xs={12} md={6} style={{ textAlign: 'center', marginTop: 0 }}>
                            <img src='images/course_page/Prof_Serv_Logo.png' style={{ width: '30%' }} />
                        </Grid>
                    )}
                </Grid>
            </div>
            <div style={{
                fontFamily: 'sans-serif',
                color: '#FFFFFF',
                padding: isMobile ? '50px 5%' : '100px 5%',
                margin: "auto",
                width: isMobile ? null : 900,
            }}>
                <Typography style={{ textAlign: "center", margin: "auto", paddingBottom: 40, color: "white", fontSize: 32 }}>
                    Help when you need it
                </Typography>
                <Grid container spacing={4} justifyContent="space-between" style={{ textAlign: isMobile ? "center" : null }}>
                    <Grid item xs={12} md={6}>
                        <Typography style={{ fontSize: 16, color: "#C8C8C8", marginBottom: 10 }}>
                            Shuffle’s Professional Services help you solve problems at your convenience. This is on top of priority support that you get with license such as upgrades, solution configuration, help with deployment, bug fixes, and additionally periodic health checks twice a year.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography style={{ fontSize: 16, color: "#C8C8C8", marginBottom: 10 }}>
                            It includes, but is not limited to, new Integrations or Apps, Workflow development and special development of the Shuffle platform itself. Advice and process improvements are part of our professional services goal to help operations teams work more effectively in a more standardized manner.
                        </Typography>
                        {/* <div
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleScroll}
                            style={{
                                cursor: 'pointer',
                                textDecoration: 'none',
                                display: 'flex',
                                justifyContent: isMobile ? 'center' : 'flex-start',
                            }}
                        >
                            <Typography
                                style={{
                                    fontSize: 18,
                                    color: "rgba(255, 132, 68, 1)",
                                    marginTop: 32,
                                    fontFamily: "Inter, Helvetica, Arial, sans-serif",
                                    fontWeight: 550,
                                }}
                            >
                                See our support options →
                            </Typography>
                            <div style={{
                                bottom: 0,
                                left: 0,
                                width: isHovered ? 210 : 0,
                                height: 2,
                                background: 'rgba(255, 132, 68, 1)',
                                transition: 'width 0.3s ease',
                            }}></div>
                        </div> */}
                    </Grid>
                </Grid>
            </div>
            {/* <div style={{ position: "relative", height: "auto", zIndex: 10, padding: '50px 5%', }}>
                <Typography style={{ textAlign: "center", color: "white", fontSize: 32 }}>
                Professional Service covers
                </Typography>
                <Grid container style={{ textAlign: "center", justifyContent: "center", flexWrap: "nowrap", padding: 50, alignItems: "center" }}>
                    <Grid item style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {buttonItems.map((data, index) => (
                            <div style={textStyle} key={index} onClick={() => setSelected(index)}>
                                <Button variant="text" style={{ padding: 10, maxWidth: "100%", textTransform: "none", color: selected === index ? "white" : "#f86a3e", border: selected === index ? "inherit" : "1px solid #f86a3e", backgroundColor: selected === index ? "#f86a3e" : "#212121", }}>
                                    <span style={{ width: 205 }}>
                                        {data.title}
                                    </span>
                                    <ArrowForwardIcon style={{ marginLeft: 20, }} />
                                </Button>
                            </div>
                        ))}
                    </Grid>
                    {renderSelectedDiv()}
                    <Grid item style={{ width: 700, display: 'flex', height: 275, border: "1px solid #f86a3e", padding: 20, fontSize: '16px', color: '#ffffff', backgroundColor: '#212121', borderRadius: '16px', }}>
                        <Typography variant="body1" style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: buttonItems[selected].text }}>
                        </Typography>
                    </Grid>
                </Grid>
            </div> */}
            <div style={{
                minWidth: isMobile ? '100%' : 1000,
                maxWidth: isMobile ? '100%' : 1000,
                margin: "auto",
                position: "relative",
                height: "auto",
                marginTop: 20,
                marginBottom: isMobile ? 80 : 10,
                zIndex: 10,
            }}>
                <Typography style={{ width: isMobile ? 300 : null, textAlign: "center", textTransform: "capitalize", margin: "auto", color: "white", fontSize: 32 }}>
                    Professional Service covers
                </Typography>
                <Grid container spacing={4} style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: "center",
                    margin: 'auto',
                    alignItems: 'flex-start',
                    position: 'relative',
                    height: 'auto',
                    width: isMobile ? 300 : null,
                    marginTop: 30,
                    zIndex: 10,
                }}>
                    {cards.map((card, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index} style={{
                            borderRadius: 16,
                            padding: 14,
                            backgroundColor: "#212121",
                            height: 'auto',
                            textAlign: "center",
                            marginBottom: 16,
                            margin: 8
                        }}>
                            <Typography style={{
                                fontWeight: 550,
                                marginTop: 20,
                                marginBottom: 14,
                                color: "#ffffff",
                                fontSize: 18
                            }}>
                                {card}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </div>
            {/* <div ref={supportOptionsRef} style={{ minWidth: isMobile ? null : 1000, maxWidth: isMobile ? null : 1000, margin: "auto", position: "relative", height: "auto", marginTop: isMobile ? 520 : 50, zIndex: 10, }}>

                <Typography style={{ textAlign: "center", textTransform: "capitalize", margin: "auto", marginBottom: 55, paddingLeft: isMobile ? null : null, color: "white", fontSize: 32 }}>
                    Support options
                </Typography>
                {isMobile ?
                    <Grid container style={{}}>
                        <Grid item style={{ width: isMobile ? 365 : "auto", padding: 10 }}>
                            {rows.map((row, index) => {
                                const date = row.Standard;
                                const handleClick = () => {
                                    if (row.originalPrice === "-") {
                                        if (isCloud) {
                                            ReactGA.event({
                                                category: "training",
                                                action: "click_on_bookseat_private",
                                                label: "",
                                            })
                                        }
                                        window.location.href = `mailto:shuffle-support@shuffler.io?subject=Request%20for%20Private%20Training&body=Please%20share%20details%20for%20training%20such%20as%20company%20details,%20number%20of%20trainees,%20dates,%20and%20other%20notes.%20We%20will%20be%20in%20touch%20:)`;
                                    } else {
                                        if (isCloud) {
                                            ReactGA.event({
                                                category: "training",
                                                action: "click_on_bookseat_public",
                                                label: "",
                                            })
                                        }
                                    }
                                };
                                const buttonStyle = {
                                    backgroundColor: "#1a1a1a",
                                    color: "#FF8444",
                                    borderRadius: 50,
                                    width: 215,
                                    height: 52,
                                    marginTop: 30,
                                    fontSize: 20,
                                    border: "1px solid #f86a3e", // Initial border style
                                    textTransform: "none",
                                    boxShadow: 'none',
                                    transition: 'border-color 0.3s ease',
                                };

                                const hoverStyle = {
                                    border: `1px solid #FF8444`, // Border color on hover
                                };

                                return (
                                    <Card key={index} style={{ marginBottom: 20 }}>
                                        <CardContent>
                                            <Typography variant="h6">{row.serviceName}</Typograph>
                                            <Typography variant="subtitle1" style={{ fontSize: 25 }}>Standard: {row.Standard}</Typography>
                                            <Typography variant="subtitle1" style={{ fontSize: 25 }}>Premium: {row.Premium}</Typography>
                                        </CardContent>
                                    </Card>
                                );
                            })}
                        </Grid>
                    </Grid>
                    :
                    <Grid container style={{ justifyContent: isMobile ? null : "center" }}>
                        <Grid item style={{ width: "auto", flexDirection: "no-wrap", border: "1px solid #494949", padding: 10, fontSize: '16px', color: '#ffffff', backgroundColor: '#1A1A1A', borderRadius: '16px', }}>
                            <TableContainer style={{ boxShadow: "none", display: "flex", maxWidth: isMobile ? 300 : 1000, minWidth: isMobile ? 300 : 1000 }} component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#1A1A1A", }}>
                                            <TableCell align='left' style={{ fontSize: 24, width: 300 }}>Services</TableCell>
                                            <TableCell align="center" style={{ fontSize: 24, width: 200 }}><DisplaySettingsIcon /> Standard</TableCell>
                                            <TableCell align="center" style={{ fontSize: 24, width: 200 }}><VolunteerActivismIcon /> Premium</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, index) => {
                                            const date = row.Standard;
                                            const name = row.serviceName;
                                            const handleClick = () => {
                                                console.log("demo")
                                            };
                                            return (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ borderRadius: 8, height: 30 }}>
                                                    <TableCell align='left' style={{ fontSize: 16, fontWeight: 600, backgroundColor: "#1A1A1A", display: "flex", height: 30, justifyContent: "center", flexDirection: "column", alignItems: "start" }}>
                                                        {name}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: 18, width: 130 }}>{date}</TableCell>
                                                    <TableCell align="center" style={{ fontSize: 18 }}>{row.Premium}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: "#f86a3e",
                                        color: "#ffffff",
                                        borderRadius: 8,
                                        padding: "5px 10px",
                                        fontSize: 16,
                                        textTransform: "capitalize",
                                        width: "100%",
                                        fontWeight: 600,
                                    }}
                                    onClick={() => console.log('Request support info clicked')}
                                >
                                    Request support info →
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>}
            </div> */}
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                style={{
                    background: '#212121',
                    borderRadius: 16,
                    padding: isMobile ? 20 : 30,
                    maxWidth: isMobile ? '90%' : 700,
                    margin: 'auto',
                    marginTop: 85,
                    flexDirection: isMobile ? 'column' : 'row',
                    textAlign: isMobile ? 'center' : 'left'
                }}
            >
                <Grid item style={{ marginBottom: isMobile ? 20 : 0 }}>
                    <img src='images/course_page/Prof_Serv_Logo.png' style={{ width: 60, marginRight: isMobile ? 0 : 40 }} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginBottom: isMobile ? 20 : 0 }}>
                    <Typography variant="h6" component="div" style={{ fontWeight: 'bold' }}>
                        Need more <span style={{ fontWeight: 700 }}>information?</span>
                    </Typography>
                    <Typography variant="body1" color="textSecondary">
                        Our team will reply back soon!
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#f86a3e',
                            color: '#FFFFFF',
                            textTransform: "capitalize",
                            borderRadius: 8,
                            padding: '5px 10px',
                            fontSize: 16,
                        }}
                        onClick={() => window.location.href = '/contact'}
                    >
                        Get help
                    </Button>
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default ProfessionalSupport;


const PaddingWrapper = ({children, isLoggedIn})=>{

    console.log("isLoggedIn", isLoggedIn)

	const { leftSideBarOpenByClick } = useContext(Context)
	return(
		<div style={{height: "auto", paddingTop: 90,  marginLeft: isLoggedIn ? leftSideBarOpenByClick ? 200 : 0 : 0, transition: "margin-left 0.3s ease"}}>
			{children}
		</div>
	)
}

const Wrapper = memo(({children,isLoggedIn})=>{

	return(
		<PaddingWrapper isLoggedIn={isLoggedIn}>
			{children}
		</PaddingWrapper>
	)
})