import React, {useState} from 'react';
import ReactMarkdown from 'react-markdown';

import ReactGA from 'react-ga4';
import theme from "../theme.jsx";
import { useParams, useNavigate, Link } from "react-router-dom";

import {
	Typography, 
	Button, 
	Divider
} from '@mui/material';

const Blog = (props) => {
	const { bodyWidth, isMobile, inputBlogs } = props;

	const params = useParams();
	console.log("Params: ", params)
	//var props = JSON.parse(JSON.stringify(defaultprops.valueOf()))
	//var props = Object.assign({selected: false}, defaultprops)
	//let props = defaultprops.map((item) =>
  //  Object.assign({}, item, {selected:false})
	//)

	//console.log("PROPS: ", props)

	//props.match = {}
	//props.match.params = params

	const [isLoaded, setIsLoaded] = useState(false);
  const [blog, setBlog] = useState({});

	// ![Invinsense image](/images/blogs/invinsense.png)
	const getBlogData = (blogname) => {
		console.log("FETCH DATA")
		const posts = [{
			"uri_path": "infopercept",
			"title": "Partnership with Infopercept",
			"shortDescription": "Short description",
			"content": `# Shuffle's first partnership
			
With a high impact year with high growth behind us, Shuffle has now turned it's eyes towards becoming a sustainable business. This however, isn't a small task for an open source project - which is why we've now partnered with these great people. Partnerships can take many forms, and Infopercept have proven themselves to have a genuine interest and expertise in the cyber security field, which exactly why we partnered with them to create a new offering. They are a delight to work with, and with a shared vision for Shuffle, we'll get more done together.
	
## Why partner?
The hardest part of building a an automation platform is to have a real environment to build use-cases for. Infopercept, with their expertise in cybersecurity and technology, sales and marketing, as well as a good vision for the future is the perfect match for Shuffle to build the future of security.

## What we've made
Monitoring enterprise environments is hard and complex. They have people, departments, software platforms and much more, making cohesiveness hard to achieve. Together with Infopercept, we've built the [Invinsense platform](https://www.infopercept.com/Invinsense-cybersecurity-platform), which brings together different Open Source tools to achieve anything you could dream of, without the cost of commercial products packaged into it. It has the capabilities of SIEM, Endpoint Detection, Vulnerability Management, Threat Intel, Network monitoring and everything else you need, and uses Shuffle as the heart of the operation to fit it to YOUR environment and services.

[Click here to learn more about Invinsense](https://www.infopercept.com/invinsense-cybersecurity-platform)

![Invinsense overview](/images/blogs/tinified/invinsense.jpg)

## Who are Infopercept?
Infopercept's Vision and core values revolve around making organization more aware and secure through the core values of Honesty, Transparency and Knowledge, so as to enable them to make better informed decision about their Security Practices & goals. With our synergistic vision to combine, technical expertise and professional experience we aim to further establish our place as a one stop shop for our clients and partners’ cybersecurity and accreditation needs.
			
"Our specialized core team comprises of experienced veterans, technical experts & security enthusiasts having good practical experience & continuous knowledge in the Cybersecurity domain, latest trends and Security innovations, ensuring that you always get the best security approach & solution for your specific business needs exactly the way you want it to be." - [Infopercept](https://www.infopercept.com/)
			 `,
			"headerImage": "/images/blogs/tinified/shuffle_and_infopercept_joint.png",
			"release_day": "27.01.2021",
			"writerName": "Fredrik O",
		},
		{
			"uri_path": "creators",
			"title": "Earning money as a creator in Shuffle",
			"shortDescription": "Short description",
			"content": `# TBD: Title

... Tried some NFT stuff
... Tried getting people on board
... Incentives :think:

With a high impact year with high growth behind us, Shuffle has now turned it's eyes towards becoming a sustainable business. This however, isn't a small task for an open source project - which is why we've now partnered with these great people. Partnerships can take many forms, and Infopercept have proven themselves to have a genuine interest and expertise in the cyber security field, which exactly why we partnered with them to create a new offering. They are a delight to work with, and with a shared vision for Shuffle, we'll get more done together.
	
## Why partner?
The hardest part of building a an automation platform is to have a real environment to build use-cases for. Infopercept, with their expertise in cybersecurity and technology, sales and marketing, as well as a good vision for the future is the perfect match for Shuffle to build the future of security.

## What we've made
Monitoring enterprise environments is hard and complex. They have people, departments, software platforms and much more, making cohesiveness hard to achieve. Together with Infopercept, we've built the [Invinsense platform](https://www.infopercept.com/Invinsense-cybersecurity-platform), which brings together different Open Source tools to achieve anything you could dream of, without the cost of commercial products packaged into it. It has the capabilities of SIEM, Endpoint Detection, Vulnerability Management, Threat Intel, Network monitoring and everything else you need, and uses Shuffle as the heart of the operation to fit it to YOUR environment and services.

[Click here to learn more about Invinsense](https://www.infopercept.com/invinsense-cybersecurity-platform)

![Invinsense overview](/images/blogs/tinified/invinsense.jpg)

## Who are Infopercept?
Infopercept's Vision and core values revolve around making organization more aware and secure through the core values of Honesty, Transparency and Knowledge, so as to enable them to make better informed decision about their Security Practices & goals. With our synergistic vision to combine, technical expertise and professional experience we aim to further establish our place as a one stop shop for our clients and partners’ cybersecurity and accreditation needs.
			
"Our specialized core team comprises of experienced veterans, technical experts & security enthusiasts having good practical experience & continuous knowledge in the Cybersecurity domain, latest trends and Security innovations, ensuring that you always get the best security approach & solution for your specific business needs exactly the way you want it to be." - [Infopercept](https://www.infopercept.com/)
			 `,
			"headerImage": "/images/blogs/tinified/shuffle_and_infopercept_joint.png",
			"release_day": "27.01.2021",
			"writerName": "Fredrik O",
		}
		]

		if (params.key !== undefined) {
			const found = posts.find(post => post.uri_path === params.key)
			if (found !== null && found !== undefined) {
				setBlog(found)
				document.title = found.title
			} else {
				setBlog({
					"content": "# Couldn't find the blogpost you're looking for", 
				})
			}
		}
		setIsLoaded(true)

		/*
		db.collection("blogs").doc(blogname).get()
		.then(function(data) {
			if (data.exists) {
				console.log(data.data())

				setBlog(data.data())
				setIsLoaded(true)

    		document.addEventListener("keydown", keypress, false)
				document.title = data.data().title
			} else {
				setBlog({content: "# Start writing here", published: false})
				document.title = "New blog"
			}
			setIsLoaded(true)
			window.scrollTo(0, 0)
		})
		.catch(function(error) {
			console.error("Failed getting blog: ", error)
		})
		*/
	}

	/*
	if (firstrequest) {
		console.log("REQUEST: ")
		setFirstrequest(false)
		console.log(props.match.params)
	}
	*/

	if (inputBlogs !== undefined && inputBlogs.length > 0 && blog !== undefined && blog !== null && Object.getOwnPropertyNames(blog).length === 0 && !isLoaded) {
		console.log("DONT NEED TO GET BLOGS!: ", inputBlogs)
		const curblog = inputBlogs.find(curblog => curblog.linkId === params.blogid)
		console.log("ID: ", inputBlogs[0].linkId)
		console.log("blogid: ", params.blogid)
		if (curblog === null || curblog === undefined) {
			getBlogData(params.blogid)
		} else {
			console.log("FOUND!: ", curblog)
			setBlog(curblog)
		}
		setIsLoaded(true)
	} else if (blog !== undefined && blog !== null && Object.getOwnPropertyNames(blog).length === 0 && !isLoaded) {
		console.log("SETTING HERE?")
		getBlogData(params.blogid)
	}

	function InnerLink(props) {
		return <a rel="noopener noreferrer" href={props.href} target="_blank" style={{color: theme.palette.primary.main, textDecoration: "none"}}
			onClick={() => {
				ReactGA.event({
					category: "blogpost",
					action: `${blog.uri_path}_{props.children}_click`,
					label: "",
				})
			}}>{props.children}</a>
	}

	function Img(props) {
		return <img style={{marginTop: 15, marginBottom: 15, maxWidth: isMobile ? "100%" : "100%"}} alt={props.alt} src={props.src}/>
	}

	const buttonStyle = {borderRadius: 30, height: 50, width: 260, margin: isMobile ? "15px auto 15px auto" : 20, fontSize: 18,}
	const showSidebar = bodyWidth > 1366 && bodyWidth !== 1 
	const htmlContent = isLoaded ? 
		<div style={{display: "flex"}}>
			<div style={{flex: 1, maxWidth: 1024, }}>
				<div style={{display: "flex", width: isMobile ? "100%" : "100%",}}>
					<div style={{width: showSidebar ? 850 : "100%",}}>
						<img alt={blog.title} src={blog.headerImage} style={{width: isMobile ? "100%" : "100%",}} />
						<Typography variant="body1" style={{padding: isMobile ? 20 : 0, }}>
							<ReactMarkdown 
								escapeHtml={false}
								source={blog.content} 
								renderers={{
									image: Img, 
									//text: Text,
									link: InnerLink,
									//blockquote: BlockQuote,
									//code: Code,
								}}
							/>
						</Typography>
						<Divider style={{marginTop: 50, marginBottom: 50,}}/>
						<Typography variant="body2" style={{color: "#7d7f82", }}>
							Want to learn more?
						</Typography> 
						<Link to={"/contact"} style={{textDecoration: "none"}}>
							<Button
								variant={"contained"}
								color={"primary"}
								style={buttonStyle}
								onClick={() => {
									ReactGA.event({
										category: "blogpost",
										action: `${blog.uri_path}_contact_us_click`,
										label: "",
									})	
								}}
								>
								Contact us 
							</Button>
						</Link>
						{/*
						<div style={{width: "100%"}}>
							<Typography variant="h3" style={{}}>
								Comment
							</Typography>
						</div>
						*/}
					</div>
				</div>
			</div>
		</div> : null

	const mobileView = isLoaded ? 
		<div style={{marginLeft: (isMobile ? 0 : "auto"), marginRight: (isMobile ? 0 : "auto"), width: (isMobile ? "100%" : 1024)}}>	
			{htmlContent}
		</div> 
		: 
		null

	return (
		<div style={{paddingTop: 50, backgroundColor: "inherit", minHeight: 1024, paddingBottom: 200, }}>
			{mobileView}
		</div>
	)
}

export default Blog
