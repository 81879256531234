import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import { useNavigate, Link, useParams } from "react-router-dom";
import NewNewsletter from "../components/FooterNewsletter.jsx";
import ReactGA from 'react-ga4';
import { SocialIcon } from 'react-social-icons';
import {
    Button,
    Grid,
    Typography,
} from "@mui/material";

const surfaceColor = "#27292D";

// Should be different if logged in?
const NewFooter = (props) => {
    const { globalUrl, isLoaded, serverside, userdata } = props;

  	let navigate = useNavigate();
    const isCloud = serverside === true || typeof window === 'undefined' ? true : window.location.host === "localhost:3002" || window.location.host === "shuffler.io";

    const parsedWidth = isMobile ? "100%" : "100%";
    const mediaSize = isMobile ? 40 : 40
    const mediaStyle = {
        height: mediaSize,
        width: mediaSize,
        color: "white",
        margin: isMobile ? 15 : 10,

        //margin: 10,
        //marginBottom: 15,
        // backgroundColor: "white",
        //borderRadius: 30,
    }
    const socialButtons =
        <div style={{ display: isMobile ? "contents" : null,}}>
            <SocialIcon bgColor="white" target="_blank" rel="noopener noreferrer" network={"twitter"} url={"https://twitter.com/shuffleio"} style={{height: mediaSize, width: mediaSize, color: "white", margin: isMobile ? 15 : 10, marginLeft: isMobile ? 40 : null}} />
            <SocialIcon bgColor="white" target="_blank" rel="noopener noreferrer" network={"github"} url={"https://github.com/shuffle/shuffle"} style={mediaStyle} />
            <SocialIcon bgColor="white" target="_blank" rel="noopener noreferrer" network={"linkedin"} url={"https://www.linkedin.com/company/shuffleio"} style={mediaStyle} />
        </div>

    const FooterDataBrowser = (
        <div style={{ marginTop: 188 }}>
            <Grid
                container spacing={2}
                style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", height: isMobile ? "" : 549, padding: 20,  backgroundColor: "var(--Container, #212121)", justifyContent: isMobile ? "left" : "center"}}
            >
                <Grid item style={{ display: "flex",  flexDirection:  "column", marginTop: 48, justifyContent:  "left", textAlign:  "left" }}>
                    <span variant="text" color="secondary" style={{ fontSize: 16, fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", marginLeft: 7, fontStyle: "normal", marginBottom: 14, fontWeight: 800, lineHeight: "normal", color: "var(--label-grey-text, #FFFFFF)" }}>
                        Company
                    </span>
                    <Link to="/docs/about" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            About us
                        </Button>
                    </Link>
                    <Link to="/contact" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Contact us
                        </Button>
                    </Link>
                    <Link to="/partner" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Partners
                        </Button>
                    </Link>
                    <a href="mailto:support@shuffler.io?subject=Application for a job at Shuffle" style={{}} rel="noopener noreferrer" target="_blank">
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Careers
                        </Button>
                    </a>
                </Grid>
                <Grid item style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",display: "flex", margin:  "auto", flexDirection: "column", marginTop: isMobile ? 48 : 48, marginRight: isMobile? 0: null, marginLeft: isMobile? 30: 50, justifyContent:  "left", textAlign: "left" }}>
                    <div variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontSize: 16, marginLeft: 7, fontStyle: "normal", marginBottom: 14, fontWeight: 800, lineHeight: "normal", color: "var(--label-grey-text, #FFFFFF)" }}>
                        Product
                    </div>
                    <a href="https://github.com/shuffle/shuffle" style={{}} rel="noopener noreferrer" target="_blank">
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
							Open Source
                        </Button>
                    </a>
                    <Link to="/docs" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                           	Documentation 
                        </Button>
                    </Link>
                    <Link to="/usecases" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Usecases
                        </Button>
                    </Link>
                    <Link to="/search?tab=apps" style={{}}>
                        <Button variant="text" color="secondary" style={{ justifyContent: "flex-start", fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                        	Apps
                        </Button>
                    </Link>
                    {/* <Link to="/pricing" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Pricing
                        </Button>
                    </Link> */}
                    <Link to="/training" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
							Training
                        </Button>
                    </Link>
                    <Link to="/professional-services" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Professional Services
                        </Button>
                    </Link>
                </Grid>
                <Grid item style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",display: "flex", margin:  "auto", flexDirection: "column", marginTop: isMobile ? 48 : 48, marginRight: isMobile? 170: null, marginLeft: isMobile? null: 25, justifyContent:  "left", textAlign: "left" }}>
                    <div variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontSize: 16, marginLeft: 7, fontStyle: "normal", marginBottom: 14, fontWeight: 800, lineHeight: "normal", color: "var(--label-grey-text, #FFFFFF)" }}>
                       	Pages 
                    </div>
                    <Link to="/chat" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Experimental AI
                        </Button>
                    </Link>
                    <Link to="/docs/compliance" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none", justifyContent: "left" }}>
                            Compliance
                        </Button>
                    </Link>
                    <a rel="noopener noreferrer" href="https://discord.gg/B2CBzUm" target="_blank" style={{ textTransform: "none" }}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none", justifyContent: "left" }}>
							Community
                        </Button>
                    </a>
                    <Link to="/creators" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Creators
                        </Button>
                    </Link>
                    <a rel="noopener noreferrer" href="https://medium.com/shuffle-automation" target="_blank" style={{ textTransform: "none" }}>
                        <Button variant="text" color="secondary" style={{fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)", fontWeight: 400, fontSize: 16, textTransform: "none", justifyContent: "left" }}>
                            Blog
                        </Button>
                    </a>
                    <Link to="/docs/privacy_policy" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Privacy Policy
                        </Button>
                    </Link>
                    <Link to="/docs/terms_of_service" style={{}}>
                        <Button variant="text" color="secondary" style={{ fontFamily: "var(--zds-typography-base,Inter,Helvetica,arial,sans-serif)",fontWeight: 400, fontSize: 16, textTransform: "none" }}>
                            Terms of Service
                        </Button>
                    </Link>
                </Grid>
                <Grid
                    style={{
                        // minHeight: isMobile ? null : !80,
                        marginTop: 48,
                        marginLeft: isMobile ? 18 : 220,
                        textAlign: isMobile ? "center" : null,
                        // marginRight: isMobile ? null : 65,
                        height: isMobile ? null : 320,
                        width: isMobile ? null : 520,
                        // maxWidth: isMobile ? "100%" : 1880,
                        background: "var(--disabled-on-container, #2F2F2F)",
                        borderRadius: 16,
                    }}
                >
                    <NewNewsletter globalUrl={globalUrl} isMobile={isMobile} />
                </Grid>
                <Grid container spacing={2} style={{ marginTop: isMobile? 20:null, marginBottom: isMobile?20:null,alignItems: isMobile ? "center" : "baseline", justifyContent: isMobile ? "center":null, display: "flex", backgroundColor: "var(--Container, #212121)" }}>
                    <Grid item xs={10} style={{ justifyContent: isMobile ? "center" : null,   }}>
                        <Link to="/" style={{ textDecoration: "none", }}>
                            <Button variant="text" color="secondary" style={{ textTransform: "none", background: "none", alignItems:"center" }} onClick={() => {
                                if (isCloud) {
                                    ReactGA.event({
                                        category: "header",
                                        action: "home_click",
                                        label: "",
                                    })
                                }
                            }}>
                                <img src={"/images/logos/orange_logo.svg"} alt="shuffle logo" style={{ height: 24, width: 24, marginLeft: isMobile? 36:85 }} />
                                <Typography style={{ fontSize: 24, fontWeight: 500, width: 105 }}>Shuffle</Typography>
                                {/* <Typography style={{display:"flex"}}><div style={{marginRight:13, marginLeft:1,borderLeft: "1px solid #ffffff",height: 24}}></div>EU&nbsp;&nbsp;US</Typography> */}
                                {/* <img alt="Logo Shuffle frontpage lockup" src={"/images/white_logo_new.png"} style={{margin: "auto", height: 22, }}/> */}
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item xs={2} style={{ display : isMobile ? "contents" : null}}>
                        {socialButtons}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );

    // Reroute if the user is logged in
    // const landingSite = isLoggedIn ? <Workflows globalUrl={globalUrl}{...props} /> : <div style={bodyDivStyle}>{landingpageData}</div>
    const footer = <div style={{}}>{FooterDataBrowser}</div>;
    const loadedCheck = <div>{footer}</div>;
    // {landingpageDataMobile}

    return <div style={{ overflow: "hidden" }}>{loadedCheck}</div>;
};
export default NewFooter;
