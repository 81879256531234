import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Grid, Container } from '@mui/material';
import theme from '../theme.jsx';
import React, { useState, useEffect } from 'react';
import {
    Typography,
} from '@mui/material';
import PartnerItems from "../components/PartnerItems.jsx"; 

const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
};
		
//<PartnerItems partnerLogo="images/partner/partners/logo-cogital.png" partnerText="Subsidiary of the Neo-Soft Group, Cogital is an expert in cybersecurity. Do you need to deploy solutions to protect your information system?" partnerUrl="https://www.cogital-it.com/" partnerName="Cogital" />,
const PartnerCarousel = (props) => {
	const handleDragStart = (e) => e.preventDefault();
	//if you add more partner Text it will hide partnerurl so make sure to mation height for partner text
	const items=[
		<PartnerItems partnerLogo="images/partner/partners/Pandum_Logo.png" partnerText="PT. Pandum Jagadata Indonesia is a company that specializes in Managed Detection and Response (MDR) services in Indonesia." partnerUrl="https://www.pandum.co.id?src=shuffle" partnerName="PT. Pandum Jagadata" />,
		<PartnerItems partnerLogo="images/partner/partners/infopercept.png" partnerText="Infopercept’s vision and core values revolve around making organizations more secure through the core values of Honesty, Transparency and Knowledge, to enable them to make better-informed decisions about their security practices & goals" partnerUrl="https://www.infopercept.com?src=shuffle" partnerName="Infopercept" />,
		<PartnerItems partnerLogo="images/partner/partners/revathi_logo.png" partnerText="Revathi keeps your dream of security automation alive. With VSOC services, Revathi can help you secure your environment anywhere in the world." partnerUrl="https://revathi.it?src=shuffle" partnerName="Revathi" />,
		<PartnerItems partnerLogo="images/partner/partners/socfortress_logo.svg" partnerText="SOCFortress is a SaaS company that unifies Observability, Security Monitoring, Threat Intelligence and Security Orchestration, Automation, and Response (SOAR)." partnerUrl="https://www.socfortress.co?src=shuffle" partnerName="SOCFortress" />,
		<PartnerItems partnerLogo="images/partner/partners/intra_logyc_logo.jpg" partnerText="Shuffle reseller in Malaysia, focused on Cybersecurity in the finance industry. Contact Harshad for more info: +60 11-1223 0330." partnerUrl="https://intralogyc.com?src=shuffle" partnerName="Intra Logyc Sdn Bhd" />,
		<PartnerItems partnerLogo="images/partner/partners/Fronix_Official_Logo.png" partnerText="Shuffle reseller in Malaysia, focused on Cybersecurity in the finance industry. Contact Harshad for more info: +60 11-1223 0330." partnerUrl="https://www.fronix.com.my?src=shuffle" partnerName="Fronix Sdn Bhd" />,
	]   

	return (
			<Grid style={{ marginTop: 15, }}>
					<Typography variant="h6" style={{ textAlign: "center", marginTop: 100, }}>
							Find a Partner
					</Typography>
					<Typography variant="body1" style={{ marginTop: 10, marginBottom: 10, textAlign: "center", }}>
							Looking for a unique solution or service from a trusted and capable Shuffle partner? We have a global partner ecosystem ready to adapt to your business needs.
					</Typography>
					<div style={{ height: "250px", position: "relative", }} >
						<AliceCarousel
								style={{ backgroundColor: theme.palette.surfaceColor }}
								items={items}
								infiniteLoop
								mouseTracking
								responsive={responsive}
								// activeIndex={activeIndex}
								controlsStrategy="responsive"
								autoPlay={true}
								autoPlayInterval={2000}
								infinite={true}
								disableButtonsControls
								onClick={(e) => {
									console.log("Click: ", e.target)
								}}
						/>
					</div>
			</Grid>
		);
};

export default PartnerCarousel; 
