import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import theme from '../theme.jsx';
import ReactGA from 'react-ga4';
import { isMobile } from "react-device-detect"
import AppSearch from '../components/Appsearch.jsx';
import { toast } from 'react-toastify';
import {
	AddPhotoAlternate as AddPhotoAlternateIcon,
	LockOpen as LockOpenIcon,
	FileCopy as FileCopyIcon,
	Delete as DeleteIcon,
	Remove as RemoveIcon,
	Add as AddIcon,
	CheckCircle as CheckCircleIcon,
	AttachFile as AttachFileIcon,
	Apps as AppsIcon,
	ErrorOutline as ErrorOutlineIcon,
	AddAPhoto as AddAPhotoIcon,
	AddAPhotoOutlined as AddAPhotoOutlinedIcon,
	ZoomInOutlined as ZoomInOutlinedIcon,
	ZoomOutOutlined as ZoomOutOutlinedIcon,
	Loop as LoopIcon,
	Close as CloseIcon, 
	Restore as RestoreIcon,
	Edit as EditIcon,
	BubbleChart as BubbleChartIcon,
	MoreVert as MoreVertIcon,
	VerifiedUser as VerifiedUserIcon,
	InsertLink as InsertLinkIcon, 
	LocationOn as LocationOnIcon, 
} from "@mui/icons-material";

import {
	Box, 
	Typography,
	Badge,
	Tooltip,
	Zoom,
	Fade,
	Button,
	Tabs,
	Grid,
	Tab,
	IconButton,
	Paper,
	Menu,
	MenuItem,
	Chip,
	Divider,
	FormControlLabel,
	Select,
	FormControl,
	Switch,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	TextField,
	Breadcrumbs,
	CircularProgress,
} from "@mui/material";

import AvatarEditor from "react-avatar-editor";
import { SocialIcon } from 'react-social-icons';
import { useNavigate, Link, useParams } from "react-router-dom";
import WorkflowPaper from "../components/WorkflowPaper.jsx";
import StatisticalData from "../components/StatisticalData.jsx";
import CreatorSuggestions from "../components/CreatorSuggestions.jsx";

const surfaceColor = "#27292D";
const Creator = (defaultprops) => {
	const { globalUrl, serverside, isLoaded, size, userdata, setFrameworkData, frameworkData } = defaultprops;
	let navigate = useNavigate();
	const params = useParams();
	var props = JSON.parse(JSON.stringify(defaultprops))
	props.match = {}
	props.match.params = params
	const ref = useRef()

	var image = "";
	var upload = "";
	const userImageSize = isMobile ? 100 : 150
	const [newSelectedApp, setNewSelectedApp] = React.useState({})
	const [defaultSearch, setDefaultSearch] = React.useState("")
	const [AppReload, setAppReload] = React.useState(false)
	const [cy, setCy] = React.useState()
	const [openImageModal, setOpenImageModal] = useState("");
	const [selectionOpen, setSelectionOpen] = React.useState(false)
	const [curTab, setCurTab] = React.useState(0);
	const [curTabUrl, setCurTabUrl] = React.useState(0);
	const [creatorProfile, setCreatorProfile] = React.useState({});
	const [creatorApps, setCreatorApps] = React.useState([]);
	const [creatorWorkflows, setCreatorWorkflows] = React.useState([]);
	const [firstLoad, setFirstLoad] = React.useState(false);
	const [actionImageList, setActionImageList] = React.useState([]);
	const [discoveryData, setDiscoveryData] = React.useState({})
	const [editing, setEditing] = React.useState(false);
	const [isShown, setIsShown] = useState(false);
	const [isProfileImageHover, setProfileImageHover] = useState(false);
	const [scale, setScale] = useState(1);
	const [disableImageUpload, setDisableImageUpload] = useState(true);
	const [rotate, setRotatation] = useState(0);
	const [file, setFile] = useState("");
	const [fileBase64, setFileBase64] = useState("");
	const [imageUploadError, setImageUploadError] = useState("");

	// Add info: 
	// - Their detections: Requires "releasing" a bunch of detections
	// - Their usable workflows 
	//
	// Add buttons for easily importing these into your system
	// - Focus on first what detection is and how it can work?
	//
	// Add info for helping with others' apps
	// - Add info about forks and such 
	// - Start making a overview for creators, including growth info
	//
	//file = "";
	const editHeaderImage = (event) => {
		file = event.target.value;
		const actualFile = event.target.files[0];
		const fileObject = URL.createObjectURL(actualFile);
		setFile(fileObject);
	}

	if (file !== "") {
		const img = document.getElementById("logo");
		var canvas = document.createElement("canvas");
		canvas.width = 174;
		canvas.height = 174;
		var ctx = canvas.getContext("2d");

		if (img)
			img.onload = function () {
				// img, x, y, width, height
				//ctx.drawImage(img, 174, 174)
				//console.log("IMG natural: ", img.naturalWidth, img.naturalHeight)
				//ctx.drawImage(img, 0, 0, 174, 174)
				ctx.drawImage(
					img,
					0,
					0,
					img.width,
					img.height,
					0,
					0,
					canvas.width,
					canvas.height
				);

				try {
					const canvasUrl = canvas.toDataURL();
					if (canvasUrl !== fileBase64) {
						//console.log("SET URL TO: ", canvasUrl)
						setFileBase64(canvasUrl);
					}
				} catch (e) {
					toast("Failed to parse canvasurl!");
				}
			};

		//console.log(img.width)
		//console.log(img.width)
		//canvas.width = img.width
		//canvas.height = img.height
	}


	const imgSize = 60;
	let croppedData = file.length > 0 ? file : fileBase64;
	//const scale = size === undefined ? 1 : size > 5 ? 3 : size
	const paperAppContainer = {
		display: "flex",
		flexWrap: "wrap",
		alignContent: "space-between",
		marginTop: 5,
	}

	const imagesize = 22;
	const SidebarPaperStyle = {
		backgroundColor: theme.palette.surfaceColor,
		overflowX: "hidden",
		position: "relative",
		padding: 30,
		paddingTop: 25,
		marginTop: 25,
		minHeight: "80vh",
		borderRadius: "5px",
	};

	const SideBar = {
		position: "sticky",
		minWidth: 250,
		maxWidth: 300,
		left: 0,
		top: 50,
		minHeight: "100vh",
		maxHeight: "100vh",
	};

	const zoomIn = () => {
		console.log("ZOOOMING IN");
		setScale(scale + 0.1);
	};

	const zoomOut = () => {
		setScale(scale - 0.1);
	};
	const rotatation = () => {
		setRotatation(rotate + 10);
	};

	const onPositionChange = () => {
		setDisableImageUpload(false);
	};

	const onCancelSaveAppIcon = () => {
		setFile("");
		setOpenImageModal(false);
		setImageUploadError("");
	};
	let editor;
	const setEditorRef = (imgEditor) => {
		editor = imgEditor;
	};
	const errorText =
		imageUploadError.length > 0 ? (
			<div style={{ marginTop: 10 }}>Error: {imageUploadError}</div>
		) : null;
	const onSaveAppIcon = () => {
		if (editor) {
			try {
				setFile("");
				const canvas = editor.getImageScaledToCanvas();
				setFileBase64(canvas.toDataURL());
				setOpenImageModal(false);
				setDisableImageUpload(true);
			} catch (e) {
				toast("Failed to set image. Replace it if this persists.");
			}
		}
	};

	const imageUploadModalView = openImageModal ? (
		<Dialog
			open={openImageModal}
			onClose={onCancelSaveAppIcon}
			PaperProps={{
				style: {
					backgroundColor: surfaceColor,
					color: "white",
					minWidth: isMobile ? "100%" : "300px",
					minHeight: "300px",
				},
			}}
		>
			<FormControl>
				<DialogTitle>
					<div style={{ color: "rgba(255,255,255,0.9)" }}>Upload App Icon</div>
				</DialogTitle>
				{errorText}
				<DialogContent style={{ color: "rgba(255,255,255,0.65)" }}>
					<AvatarEditor
						ref={setEditorRef}
						image={userdata.avatar}
						width={174}
						height={174}
						border={50}
						color={[0, 0, 0, 0.6]} // RGBA
						scale={scale}
						rotate={rotate}
						onImageChange={onPositionChange}
						onLoadSuccess={() => setRotatation(0)}
					/>
					<Divider style={dividerStyle1} />
					<Tooltip title={"New Icon"}>
						<Button
							variant="outlined"
							component="label"
							color="primary"
							style={appIconStyle}
						>
							<AddAPhotoOutlinedIcon
								onClick={() => {
									upload.click();
								}}
								color="primary"
							/>
						</Button>
					</Tooltip>
					<Tooltip title={"Zoom In"}>
						<Button
							variant="outlined"
							component="label"
							color="primary"
							style={appIconStyle}
						>
							<ZoomInOutlinedIcon onClick={zoomIn} color="primary" />
						</Button>
					</Tooltip>
					<Tooltip title={"Zoom out"}>
						<Button
							variant="outlined"
							component="label"
							color="primary"
							style={appIconStyle}
						>
							<ZoomOutOutlinedIcon onClick={zoomOut} color="primary" />
						</Button>
					</Tooltip>
					<Tooltip title={"Rotate"}>
						<Button
							variant="outlined"
							component="label"
							color="primary"
							style={appIconStyle}
						>
							<LoopIcon onClick={rotatation} color="primary" />
						</Button>
					</Tooltip>
					<Divider style={dividerStyle} />
				</DialogContent>
				<DialogActions>
					<Button
						style={{ borderRadius: "0px" }}
						onClick={onCancelSaveAppIcon}
						color="primary"
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						style={{ borderRadius: "0px" }}
						disabled={disableImageUpload}
						onClick={() => {
							onSaveAppIcon();
						}}
						color="primary"
					>
						Continue
					</Button>
				</DialogActions>
				{/*<ParsedActionHandler />*/}
			</FormControl>
		</Dialog>
	) : null;

	useEffect(() => {
		const checkIfClickedOutside = e => {
			// If the menu is open and the clicked target is not within the menu,
			// then close the menu
			if (selectionOpen && ref.current && !ref.current.contains(e.target)) {
				setSelectionOpen(false)
			}
		}

		document.addEventListener("mousedown", checkIfClickedOutside)

		return () => {
			// Cleanup the event listener
			document.removeEventListener("mousedown", checkIfClickedOutside)
		}
	}, [selectionOpen])

	const getUserProfile = (username) => {
		fetch(`${globalUrl}/api/v1/users/creators/${username}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (response.status !== 200) {
					console.log("Status not 200 for WORKFLOW EXECUTION :O!");
				}


				return response.json();
			})
			.then((responseJson) => {
				setCreatorProfile(responseJson)
				if (responseJson.specialized_apps !== undefined && responseJson.specialized_apps !== null && responseJson.specialized_apps.length > 0) {
					setActionImageList(responseJson.specialized_apps)
				}

				if (responseJson.github_userid === username && responseJson.github_username !== undefined && responseJson.github_username !== null) {
					navigate(`/creators/${responseJson.github_username}`)
				}
			})
			.catch((error) => {
				console.log(error);
			})
	}

	const getUserProfileApps = (username) => {
		fetch(`${globalUrl}/api/v1/users/creators/${username}/apps`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (response.status !== 200) {
					console.log("Status not 200 for WORKFLOW EXECUTION :O!");
				}


				return response.json();
			})
			.then((responseJson) => {
				if (responseJson.success !== false) {
					setCreatorApps(responseJson)
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}
	// const removeValue = (actionImageList,index) => {
	// 	// create a new array that excludes the value you want to remove
	// 	const newList = this.state.actionImageList[index].filter((data, index) => data !== index);
	// 	// const newList = actionImageList[index].filter(returnData => returnData !== actionImageList)
	// 	// update the list with the new array
	// 	setActionImageList(newList)
	// }

	// const getStaticData = (creator) => {
	// 	fetch(`http://172.17.14.24:5002/api/v1/users/creators/${creator}/stats`, {
	// 	  method: "GET",
	// 	  headers: {
	// 		"Content-Type": "application/json",
	// 		Accept: "application/json",
	// 	  },
	// 	})
	// 		.then((response) => {
	// 			if (response.status !== 200) {
	// 				console.log("Status not 200 for app auth :O!");
	// 			}
	// 			return response.json();
	// 		}).then((responseJson) =>{
	// 			console.log(responseJson)
	// 			return responseJson
	// 		})
	// 		.catch((error) => {
	// 			console.log("App auth loading error: "+error.toString());
	// 		})
	// }

	const setUserSpecialzedApp = (user, data) => {
		const selectedId = creatorProfile.github_userid !== undefined && creatorProfile.github_userid !== null && creatorProfile.github_userid.length == 32 ? creatorProfile.github_userid : user

		console.log(JSON.stringify(data))
		// check if it's a list or not from data
		var appData = {"user_id": selectedId, "specialized_apps": [{}]}
		if (typeof data === "object" && data.length > 0) {
			console.log("It's a list")
			appData = {"user_id": selectedId, "specialized_apps": data}
		} else {
			console.log("It's not a list")

			// check if name in data is null or not
			if (data["name"] === null || data["name"] === undefined) {
				appData["specialized_apps"] = []
			} else {
				appData["specialized_apps"][0]["name"] = data["name"]
				appData["specialized_apps"][0]["image"] = data["image_url"]
				appData["specialized_apps"][0]["category"] = data["categories"].toString()

				const finalData = actionImageList.concat(appData["specialized_apps"])
				appData["specialized_apps"] = finalData
			}
		}

		console.log(appData)

		fetch(globalUrl + "/api/v1/users/updateuser", {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			body: JSON.stringify(appData),
			credentials: "include",
		})
			.then((response) => {
				if (response.status !== 200) {
					console.log("Status not 200 for set creator :O!");
				}

				return response.json();
			})
			.then((responseJson) => {
				if (!responseJson.success && responseJson.reason !== undefined) {
					toast("Failed updating user: " + responseJson.reason);
				} else if (!responseJson.success) {
					toast("Failed updating user.")
				} else {
					toast("Sucessfully updated specialized app.")
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};
	const getUserProfileWorkflows = (username) => {
		fetch(`${globalUrl}/api/v1/users/creators/${username}/workflows`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
			},
			credentials: "include",
		})
			.then((response) => {
				if (response.status !== 200) {
					console.log("Status not 200 for WORKFLOW EXECUTION :O!");
				}

				return response.json();
			})
			.then((responseJson) => {
				if (responseJson.success !== false) {
					setCreatorWorkflows(responseJson)
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}

	useEffect(() => {
		getUserProfile(props.match.params.key)
		getUserProfileApps(props.match.params.key)
		getUserProfileWorkflows(props.match.params.key)
	}, [])

	if (serverside === true) {
		return null
	}


	// useEffect(()=>{
	// 	aboutView = aboutView
	// 	 setActionImageList( appImageList => ...appImageList)
	// }, [actionImageList])

	// useEffect(()=>{
	// 	setActionImageList( appImageList => ...appImageList)
	// }, [actionImageList])

	// useEffect(() => {
	// 	setUserSpecialzedApp(userdata.id, actionImageList)
	// }, [actionImageList])

	if (props.match.params.key !== creatorProfile.github_username && firstLoad === false && creatorProfile.github_username !== undefined && creatorProfile.github_username !== null) {

		// FIX: Removed due to infinite re-renderings happening for specific users~
		//getUserProfile(props.match.params.key)
		//getUserProfileApps(props.match.params.key)
		//getUserProfileWorkflows(props.match.params.key)
	}

	const views = {
		0: "about",
		1: "apps",
		2: "workflows",
		3: "statistics",
		4: "suggestions",
	}

	const setConfigTab = (event, inputValue) => {
		const newValue = parseInt(inputValue)
		const aboutRef = document.getElementById('aboutRef');
		const appsRef = document.getElementById('appsRef');
		const workflowRef = document.getElementById('workflowRef');
		const statRef = document.getElementById('statRef');
		setCurTabUrl(newValue)
		if (newValue === 0) {
			document.title = "Shuffle - Creator - About";
			aboutRef.scrollIntoView({ behavior: 'smooth', transition: "2s" });
		} else if (newValue === 1) {
			document.title = "Shuffle - Creator - Apps";
			appsRef.scrollIntoView({ behavior: 'smooth', transition: "2s" });
			// appsRef.current.scrollIntoView({ behavior: 'smooth' })
		} else if (newValue === 2) {
			document.title = "Shuffle - Creator - workflows";
			workflowRef.scrollIntoView({ behavior: 'smooth', transition: "2s" });
			// workflowRef.current.scrollIntoView({ behavior: 'smooth' })
		} else if (newValue === 3) {
			document.title = "Shuffle - Creator - Statistics";
			statRef.scrollIntoView({ behavior: 'smooth', transition: "2s" });
			// statRef.current.scrollIntoView({ behavior: 'smooth' })
		} else if (newValue === 4) {
			document.title = "Shuffle - Creator - Suggestions";
			statRef.scrollIntoView({ behavior: 'smooth', transition: "2s" });
		} else {
			document.title = "Shuffle - Creator";
		}
		// const urlSearchParams = new URLSearchParams(window.location.search)
		// const params = Object.fromEntries(urlSearchParams.entries())
		// const foundQuery = params["q"]
		// var extraQ = ""
		// if (foundQuery !== null && foundQuery !== undefined) {
		// 	extraQ = "&q="+foundQuery
		// }
		if ((serverside === false || serverside === undefined) && window.location.pathname.includes("/creators")) {
			//window._mfq.push(['newPageView', `?tab=${views[newValue]}`]); 
			window.history.replaceState(newValue, '', `?tab=${views[newValue]}`);
			// navigate(`?tab=${views[newValue]}`)
		}
	}
	// console.log("actionimagelist : ",actionImageList)
	const dividerStyle = {
		marginTop: 20,
		marginBottom: 20,
	}
	const dividerStyle1 = {
		marginBottom: "10px",
		marginTop: "10px",
		height: "1px",
		width: "100%",
		backgroundColor: "grey",
	};
	const appIconStyle = {
		marginLeft: "5px",
	};

	const spanStyle = {
		backgroundColor: theme.palette.inputColor,
		borderRadius: theme.palette?.borderRadius,
		padding: 8,
		minWidth: 175,
	}

	const baseWidth = isMobile ? "100%" : 720
	var aboutView =
		<div id="aboutRef" style={{ paddingTop: "30px" }}>
			{creatorProfile.self === true || actionImageList.length > 0 ?
				<span>
					<Typography variant="h6">
						Specialized apps
					</Typography>
					<div
						style={{
							display: "flex",
							maxWidth: isMobile ? "100%" : baseWidth,
							minWidth: isMobile ? "100%" : baseWidth,
							zIndex: 11,
							border: "1px solid rgba(255,255,255,0.1)",
							borderRadius: theme.palette?.borderRadius,
							textAlign: "center",
							overflow: "auto",
						}}
					>
						{!isMobile &&
							actionImageList !== undefined &&
							actionImageList !== null &&
							actionImageList.length > 0 ? (
							actionImageList.map((data, index) => {
								if (
									data.image === undefined ||
									data.image === null ||
									data.image.length === 0
								) {
									return null;
								}

								if (data.name.toLowerCase() === "shuffle tools") {
									data.image = theme.palette.defaultImage;
								}

								const returnData =
									<span key={index} style={{ zIndex: 10 }} >
										<IconButton
											style={{
												backgroundColor: "transparent",
												margin: 0,
												padding: 12,
												display: "flex",
											}}
											onClick={() => {
											}}
											onMouseEnter={() => setIsShown(true)}
											onMouseLeave={() => setIsShown(false)}
										>
											<Tooltip
												title={`${data.name}`}
												placement="top"
											>
												<Badge
													badgeContent={0}
													color="secondary"
													style={{ fontSize: 10 }}
												>
													<div
														style={{
															height: imgSize,
															width: imgSize,
															position: "relative",
															filter: "brightness(0.6)",
															backgroundColor: "#000",
															borderRadius: imgSize / 2,
															zIndex: 100,
															overflow: "hidden",
															display: "flex",
															justifyContent: "center",
														}}
													>
														<img
															style={{
																height: imgSize,
																width: imgSize,
																position: "absolute",
																top: -2,
																left: -2,
																cursor: "pointer",
																zIndex: 99,
																border: "2px solid rgba(255,255,255,0.7)",
															}}
															alt={data.name}
															src={data.image}
														/>
														{creatorProfile.self === true && isShown && (
															<Button style={{ zIndex: 120, overflow: "hidden", }} onClick={() => {
																actionImageList.splice(index, 1)
																setUserSpecialzedApp(userdata.id, actionImageList)
															}}>
																<CloseIcon style={{ width: "15px", height: "15px", overflow: "hidden", marginBottom: "50px", color: "black", backgroundColor: "white", borderRadius: "6px" }} />
															</Button>
														)}
													</div>
												</Badge>
											</Tooltip>
										</IconButton>
									</span>
								if (firstLoad) {
									appDelay += 75
								} else {
									//appDelay = 0
									return returnData
								}
								return (
									<Zoom key={index} in={true} style={{ transitionDelay: `${appDelay}ms` }} >
										{returnData}
									</Zoom>
								);
							})
						) : null}

						{creatorProfile.self === true ?
							<div style={{ display: "flex" }} >
								<Button onClick={(event) => { setSelectionOpen(oldState => !oldState) }} >
									<AddIcon style={{ width: "40px", height: "80px" }} />
								</Button>
								{selectionOpen ?
									<div style={{ width: 275, maxHeight: 400, zIndex: 12500, padding: 15, paddingRight: 35, backgroundColor: theme.palette.surfaceColor, border: "1px solid rgba(255,255,255,0.2)", borderRadius: theme.palette?.borderRadius, position: "absolute", top: 780, left: 650, }} ref={ref}>
										<Button style={{ marginLeft: "230px", width: "50px" }} onClick={(event) => { setSelectionOpen(false) }}><CloseIcon /></Button>
										<AppSearch
											defaultSearch={defaultSearch}
											newSelectedApp={newSelectedApp}
											setNewSelectedApp={setNewSelectedApp}
											userdata={userdata}
											isCreatorPage={true}
											globalUrl={globalUrl}
											actionImageList={actionImageList}
											setActionImageList={setActionImageList}
	
											setUserSpecialzedApp={setUserSpecialzedApp} 
										// index = {actionImageList}
										/>
									</div>
									: null}
							</div>
						: null}
					</div>
					<Divider style={dividerStyle} />
				</span>
			: null}
			<Typography variant="h6">
				General info
			</Typography>
			{creatorProfile.skills !== null && creatorProfile.skills !== undefined && creatorProfile.skills.length > 0 ?
				<div style={{ display: "flex", margin: 5, }}>
					<span style={spanStyle}>
						<Typography style={{
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
						}}>
							<InsertLinkIcon style={{ marginRight: 5, }} />
							<span>
								Skills
							</span>
						</Typography>
					</span>
					<Typography style={{ padding: 5, marginLeft: 20, }}>
						{creatorProfile.skills.join(", ")}
					</Typography>
				</div>
				: null}
			<div style={{ display: "flex", margin: 5, }}>
				<span style={spanStyle}>
					<Typography style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}>
						<InsertLinkIcon style={{ marginRight: 5, }} />
						<span>
							Work Status
						</span>
					</Typography>
				</span>
				<Typography style={{ padding: 5, marginLeft: 20, }}>
					{creatorProfile.work_status}
				</Typography>
			</div>
			<div style={{ display: "flex", margin: 5, }}>
				<span style={spanStyle}>
					<Typography style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}>
						<InsertLinkIcon style={{ marginRight: 5, }} />
						<span>
							Social
						</span>
					</Typography>
				</span>
				{creatorProfile.github_twitter === null || creatorProfile.github_twitter === undefined ? null :
					<Typography style={{ padding: 5, marginLeft: 20, }}>
						<SocialIcon target="_blank" rel="noopener noreferrer" network={"twitter"} url={creatorProfile.github_twitter} style={{
							height: 25,
							width: 25,
							backgroundColor: "white",
							borderRadius: 15,
						}} />
					</Typography>
				}
			</div>

			{/*creatorProfile.github_contributions !== undefined && creatorProfile.github_contributions !== null ? 
				<span style={{}}>
					<Divider style={dividerStyle} />
					
					<Typography variant="h6">
						Github Contributions
					</Typography>
					<div style={{display: "flex", margin: 5,}}>
						<span style={spanStyle}>
							<Typography style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
							}}>
								<InsertLinkIcon style={{marginRight: 5,}}/> 
								<span>
									Shuffle Core 
								</span>
							</Typography>
						</span>
						<Typography style={{padding: 5, marginLeft: 20, }}>
							{creatorProfile.github_contributions.core.contribution_count}
						</Typography>
					</div>

					<div style={{display: "flex", margin: 5,}}>
						<span style={spanStyle}>
							<Typography style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
							}}>
								<InsertLinkIcon style={{marginRight: 5,}}/> 
								<span>
									Documentation	
								</span>
							</Typography>
						</span>
						<Typography style={{padding: 5, marginLeft: 20, }}>
							{creatorProfile.github_contributions.docs.contribution_count}
						</Typography>
					</div>

					<div style={{display: "flex", margin: 5,}}>
						<span style={spanStyle}>
							<Typography style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
							}}>
								<InsertLinkIcon style={{marginRight: 5,}}/> 
								<span>
									Apps 
								</span>
							</Typography>
						</span>
						<Typography style={{padding: 5, marginLeft: 20, }}>
							{creatorProfile.github_contributions.apps.contribution_count}
						</Typography>
					</div>
					<div style={{display: "flex", margin: 5,}}>
						<span style={spanStyle}>
							<Typography style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
							}}>
								<InsertLinkIcon style={{marginRight: 5,}}/> 
								<span>
									Workflows	
								</span>
							</Typography>
						</span>
						<Typography style={{padding: 5, marginLeft: 20, }}>
							{creatorProfile.github_contributions.workflows.contribution_count}
						</Typography>
					</div>
				</span>
			: null*/}

			{/*
					<Typography variant="h6">
						Current ranking: {creatorProfile.shuffle_ranking}
					</Typography>
					<Typography variant="h6">
						Earnings: {creatorProfile.shuffle_earnings}
					</Typography>
				*/}
		</div>

	var appDelay = -50
	const activityView =
		<div id="appsRef" style={{ paddingTop: "30px" }}>
			<Typography variant="h6" style={{ marginBottom: 10 }}>
				Released apps ({creatorApps.length})
			</Typography>
			{creatorApps !== undefined &&
				creatorApps !== null &&
				creatorApps.length > 0 ? (
				<Grid style={{
					display: "flex",
					maxWidth: 1024,
					zIndex: 11,
					border: "1px solid rgba(255,255,255,0.1)",
					borderRadius: theme.palette?.borderRadius,
					textAlign: "center",
					backgroundColor: theme.palette.surfaceColor
				}}
					container direction="row" alignItems="center">
					{creatorApps.map((data, index) => {
						if (
							data.image_url === undefined ||
							data.image_url === null ||
							data.image_url.length === 0
						) {
							return null;
						}

						const returnData =
							<span key={index} style={{ zIndex: 10 }}>
								<Link to={`/apps/${data.objectID}`} style={{ textDecoration: "none", }}>
									<IconButton
										style={{
											backgroundColor: "transparent",
											margin: 0,
											padding: 12,
										}}
										onClick={() => {
											console.log("FILTER: ", data);
										}}
									>
										<Tooltip
											title={`${data.name}`}
											placement="top"
										>
											<Badge
												badgeContent={0}
												color="secondary"
												style={{ fontSize: 10 }}
											>
												<div
													style={{
														height: imgSize,
														width: imgSize,
														position: "relative",
														filter: "brightness(0.6)",
														backgroundColor: "#000",
														borderRadius: imgSize / 2,
														zIndex: 100,
														overflow: "hidden",
														display: "flex",
														justifyContent: "center",
														backgroundColor: "rgba(255,255,255,0.3)",
													}}
												>
													<img
														style={{
															height: imgSize,
															width: imgSize,
															position: "absolute",
															top: -2,
															left: -2,
															cursor: "pointer",
															zIndex: 99,
															border: "2px solid rgba(255,255,255,0.7)",
														}}
														alt={data.app_name}
														src={data.image_url}
													/>
												</div>
											</Badge>
										</Tooltip>
									</IconButton>
								</Link>
							</span>

						if (firstLoad) {
							appDelay += 50
						} else {
							//appDelay = 0
							return returnData
						}

						return (
							<Zoom key={index} in={true} style={{ transitionDelay: `${appDelay}ms` }}>
								{returnData}
							</Zoom>
						);
					})}
				</Grid>
			) : null}
		</div>

	var workflowDelay = -75
	const workflowView =
		<div id="workflowRef" style={{ paddingTop: "30px" }}>
			<Typography variant="h6" style={{ marginBottom: 10 }}>
				Released workflows ({creatorWorkflows.length})
			</Typography>
			<Grid container spacing={3} style={paperAppContainer}>
				{creatorWorkflows.map((data, index) => {
					if (firstLoad) {
						workflowDelay += 75
					} else {
						return (
							<Grid key={index} item xs={isMobile ? 12 : 6} style={{}}>
								<WorkflowPaper key={index} data={data} />
							</Grid>
						)
					}

					return (
						<Zoom key={index} in={true} style={{ transitionDelay: `${workflowDelay}ms` }}>
							<Grid item xs={isMobile ? 12 : 6} style={{}}>
								<WorkflowPaper key={index} data={data} />
							</Grid>
						</Zoom>
					)
				})}
			</Grid>
		</div>

	var statisticalDelay = -75
	const statisticalData = isMobile ? null : 
		<div id="statRef" style={{ paddingTop: "30px" }}>
			<Grid container spacing={4} style={paperAppContainer}>
				<Zoom in={true} style={{ transitionDelay: `${statisticalDelay}ms` }}>
					<Grid item xs={4} style={{}}>
						<StatisticalData
							globalUrl={globalUrl}
							creatorName={props.match.params.key}
						/>
					</Grid>
				</Zoom>
			</Grid>
		</div>

	const setConfig = (event, newValue) => {
		setCurTab(parseInt(newValue));
	}

	const saveNewProfile = () => {
		//toast("Saving your profile: TBD")
	}

	const baseView = creatorProfile.github_username === undefined || creatorProfile.github_username === null ? null :
		<Fade in={true} style={{ transitionDelay: `$0ms` }}>
			<div style={{ width: isMobile ? "100%" : 1266, }}>
				{imageUploadModalView}
				<input
					hidden
					type="file"
					ref={(ref) => (upload = ref)}
					onChange={editHeaderImage}
				/>
				{creatorProfile.banner === undefined || creatorProfile.banner === null || creatorProfile.banner.length === 0 ?
					<div style={{ height: 300, }} />
					:
					<div style={{ position: "relative", display: "flex" }}>
						<img src={creatorProfile.banner}
							style={{ display: "block", minWidth: isMobile ? "100%" : 1115, maxWidth: isMobile ? "100%" : 1115, marginLeft: isMobile ? 0 : 120, height: isMobile ? "auto" : 300, border: "1px solid grey", }}
						/>
						{creatorProfile.self === true ?
							<Button variant="contained" style={{ position: "absolute", marginLeft: "1116px", marginTop: "268px", height: "30px", fontSize: "10px" }} color="primary">Edit Banner <AddPhotoAlternateIcon style={{ width: "15px" }} /></Button>
						: null}
					</div>
				}

				<div style={{ maxWidth: baseWidth, minWidth: baseWidth, margin: isMobile ? "auto 10px auto 10px" : "auto", position: "relative", marginTop: 80, paddingBottom: "30px" }}>
					<div style={{ backgroundColor: "#383b40", borderRadius: "100%", height: userImageSize, width: userImageSize, position: "absolute", display: "flex", border: "6px solid #1f2023", top: isMobile ? -userImageSize : -userImageSize - 30, left: isMobile ? "40%" : -20, overflow: "hidden", }}
					// onMouseEnter={() => setProfileImageHover(true)}
					// onMouseLeave={() => setProfileImageHover(false)}
					>
						<img
							src={creatorProfile.github_avatar}
							style={{ display: "block", width: userImageSize, height: userImageSize, position: "absolute" }}
						/>
						{creatorProfile.self === true ?
							<Button style={{ width: "150px", height: "30px", marginTop: "120px" }} variant="contained" color="primary" onClick={() => { setOpenImageModal(true); }}><AddAPhotoIcon style={{ width: "20px" }} /></Button>
						: null}
					</div>
					<div style={{ display: "flex", }}>
						<Typography variant="h4">
							@{creatorProfile.github_username}
						</Typography>
						<span style={{ marginTop: "auto", marginBottom: "auto", marginLeft: 10, }}>
							{creatorProfile.verified === true ?
								<Tooltip title="Verified and earning from Shuffle contributions" placement="top">
									<VerifiedUserIcon style={{}} />
								</Tooltip>
								:
								null
							}
						</span>
						{!isMobile && creatorProfile.self !== true ?
							<Button
								style={{
									borderRadius: 25,
									fontSize: 11,
									color: "white",
									backgroundColor: "rgba(255,255,255,0)",
									border: "1px solid #ddf4e1",
									textTransform: "none",
									height: 35,
									margin: "auto",
									marginLeft: 25,
								}}
								onClick={() => {
									ReactGA.event({
										category: "hiring_button",
										action: `${creatorProfile.github_username}_creator_click`,
										label: creatorProfile.github_username,
									})


									if (window.drift !== undefined) {
										window.drift.api.startInteraction({ interactionId: 342345 })
									} else {
										console.log("Couldn't find drift in window.drift and not .drift-open-chat with querySelector: ", window.drift)
										toast(`We've alerted ${creatorProfile.github_username} that you would like to hire them.`)
									}
								}}
							>
								Contact	
							</Button>
							: null}
						{!isMobile && creatorProfile.self === true ?
							<Tooltip title="Full editing rights coming soon">
								<Link to={`/creators/${creatorProfile.github_username}/edit`} style={{ textDecoration: "none", }}>
									<Button
										variant="contained"
										style={{
											minWidth: 150,
											maxWidth: 150,
											borderRadius: 25,
											textTransform: "none",
											backgroundImage: editing ? "#f86a3e" : "linear-gradient(to right, #f86a3e, #f34079)",
											height: 40,
											itemAlign: "right",
											marginLeft: 450,
										}}
										onClick={() => {
											if (editing === true) {
												saveNewProfile()
											}

											setEditing(!editing)
										}}
										color="primary"
									>
										{editing ? "Save edits" : "Edit profile"}
									</Button>
								</Link>
							</Tooltip>
							: null}
					</div>
					<Typography variant="h6" style={{ marginTop: 25 }}>
						{creatorProfile.github_bio}
					</Typography>

					{isMobile ? null : 
						<div style={{ display: "flex", marginTop: 25, }}>
							<a
								rel="noopener noreferrer"
								target="_blank"
								href={creatorProfile.github_url === "" ? "https://shuffler.io" : creatorProfile.github_url}
								target="_blank"
								style={{
									textDecoration: "none",
									color: "#f85a3e",
								}}
							>
								<Typography style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
								}}>
									<InsertLinkIcon style={{ marginRight: 5, }} />
									<span>
										{creatorProfile.github_url === "" ? "https://shuffler.io" : creatorProfile.github_url.replace("https://", "").replace("http://", "")}
									</span>
								</Typography>
							</a>
							<Typography style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								marginLeft: 50,
								cursor: "pointer",
							}}
								onClick={() => {
									setCurTabUrl(1)
								}}
							>
								<span style={{ color: "white", marginRight: 5, }}>
									{creatorApps.length}
								</span>
								<span color="textSecondary">
									App{creatorApps.length === 0 ? "" : "s"}
								</span>
							</Typography>
							<Typography style={{
								display: 'flex',
								alignItems: 'center',
								flexWrap: 'wrap',
								marginLeft: 50,
								cursor: "pointer",
							}}
								onClick={() => {
									setCurTabUrl(2)
								}}
							>
								<span style={{ color: "white", marginRight: 5, }}>
									{creatorWorkflows.length}
								</span>
								<span color="textSecondary">
									workflow{creatorWorkflows.length === 0 ? "" : "s"}
								</span>
							</Typography>
							{creatorProfile.github_location !== "" ?
								<Typography style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
									marginLeft: 50,
								}}>
									<LocationOnIcon style={{ marginRight: 5 }} />
									<span style={{ color: "white", marginRight: 5, }}>
										{creatorProfile.github_location}
									</span>
								</Typography>
								: null}
						</div>
					}
				</div>
				{/* <Divider style={{maxWidth:"1290px"}} /> */}
				<Box style={{ flexGrow: 1, display: 'flex', paddingLeft: isMobile ? 0 : 140, paddingTop: 10, }}>
					{/* <div style={{
						// backgroundColor: theme.palette.surfaceColor,
						// overflowX: "hidden",
						

					}}> */}
					{isMobile ? null :
						<div style={SideBar}>
							<Tabs
								value={curTabUrl}
								orientation="vertical"
								indicatorColor="primary"
								textColor="secondary"
								onChange={setConfigTab}
								style={SidebarPaperStyle}
							>
								<Tab
									style={{ flex: 1, marginBottom: "20px", marginLeft: 10, marginRight: 0, borderBottom: "1px solid rgba(255,255,255,0.7)", textTransform: "none" }}
									label=<span>
										About
									</span>
								/>
								<Tab
									style={{ flex: 1, marginBottom: "20px", marginLeft: 10, marginRight: 0, borderBottom: "1px solid rgba(255,255,255,0.7)", textTransform: "none" }}
									label=<span>
										Apps
									</span>
								/>
								<Tab
									style={{ flex: 1, marginBottom: "20px", marginLeft: 10, marginRight: 0, borderBottom: "1px solid rgba(255,255,255,0.7)", textTransform: "none" }}
									label=<span>
										Workflows
									</span>
								/>
								<Tab
									style={{ flex: 1, marginBottom: "20px", marginLeft: 10, marginRight: 0, borderBottom: "1px solid rgba(255,255,255,0.7)", textTransform: "none" }}
									// disabled={true}
									label=<span>
										Statistics
									</span>
								/>
								<Tab
									style={{ flex: 1, marginBottom: "20px", marginLeft: 10, marginRight: 0, borderBottom: "1px solid rgba(255,255,255,0.7)", textTransform: "none" }}
									// disabled={true}
									label=<span>
										Suggestions	
									</span>
								/>
							</Tabs>
						</div>
					}

					{/* </div> */}
					<div style={{ width: isMobile ? "100%" : 768, margin: "auto", marginTop: 25, backgroundColor: isMobile ? "inherit" : theme.palette.surfaceColor, borderRadius: theme.palette?.borderRadius, border: "rgba(255,255,255,0.3)", padding: isMobile ? 10 : 40 }}>
						{aboutView}
						{activityView}
						{workflowView}
						{statisticalData}
						{isMobile ? null : 
							<CreatorSuggestions 
								globalUrl={globalUrl}
								userdata={userdata}
								creatorProfile={creatorProfile}
								creatorName={props.match.params.key}
							/>
						}
					</div>
					{/* <div style={{ marginTop: 25 }}>
						
					</div> */}
				</Box>
			</div>
		</Fade>

	return (
		<div style={{ minWidth: isMobile ? "100%" : 1266, maxWidth: isMobile ? "100%" : 1266, margin: "auto", paddingBottom: isMobile ? 0 : 1000, overflow: "hidden",  }}>
			{baseView}
		</div>
	)
}

export default Creator;
